import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { navigate } from "gatsby";
import useMedia from "use-media";
import styled from "styled-components";
import Progress from "./Progress";
import WalletButton from "./WalletButton";
import imgLogo from "../static/logo.svg";
import imgMenuBottom from "../static/menu-bottom.svg";
import imgIconBuyNTF from "../static/icons/icon-buynft.svg";
import imgIconStory from "../static/icons/icon-story.svg";
import imgIconRoadmap from "../static/icons/icon-roadmap.svg";
import imgIconLogbook from "../static/icons/icon-pencil.svg";
import imgIconGallery from "../static/icons/icon-gallery.svg";
import imgIconLink from "../static/icons/icon-link.svg";
import imgIconArrow from "../static/icons/icon-menu-arrow.svg";
import imgIconMenu from "../static/icons/icon-menu.svg";
import imgIconMenuClose from "../static/icons/icon-menu-close.svg";
import { ConfirmationDialog } from "./dialogs/ConfirmationDialog";
import { useModalDialog } from "../contexts/ModalDialog";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import theme from "../theme";
import { ENABLE_GALLERY, ENABLE_ROADMAP } from "../contexts/GameContext";

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${(props) => props.theme.gbMain};
    z-index: 10;
    border-bottom: 1px solid ${(props) => props.theme.colorSecondary};

    & .buynft:before {
        background-image: url(${imgIconBuyNTF});
    }

    & .story:before {
        background-image: url(${imgIconStory});
    }

    & .logbook:before {
        background-image: url(${imgIconLogbook});
    }

    & .gallery:before {
        background-image: url(${imgIconGallery});
    }

    & .logout:before {
        background-image: url(${imgIconLink});
    }

    & .roadmap:before {
        background-image: url(${imgIconRoadmap});
    }
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    height: 80px;
    flex-shrink: 0;
    box-sizing: border-box;

    @media (max-width: 1260px) {
        padding: 0 30px;
    }

    @media (max-width: ${(props) => props.theme.mediaS}) {
        & {
            height: 64px;
            padding: 0 10px;
        }
    }
`;

const Logo = styled.div`
    width: 150px;
    height: 29px;
    flex-shrink: 0;
    background: url(${imgLogo}) center center no-repeat;
    background-size: contain;
    cursor: pointer;

    @media (max-width: 400px) {
        width: 107px;
        height: 23px;
    }

    @media (max-width: ${(props) => props.theme.mediaL}) {
        width: 130px;
        height: 28px;
    }
`;

const NavButton = styled.div`
    font-size: 16px;
    font-style: normal;
    flex-shrink: 0;
    color: ${(props) => props.theme.colorPrimary};
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
        text-decoration: underline;
    }
`;

const Space = styled.div`
    flex-grow: 1;
    min-width: 10px;
`;

const MobileBar = styled.div<{ scroll?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${(p) => (p.scroll ? "space-around" : "center")};
    width: 100%;
    height: 35px;
    flex-shrink: 0;
    border-top: 1px solid ${(props) => props.theme.colorSecondary};
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 10px;

    & > div {
        margin-right: ${(p) => (p.scroll ? "15px" : "50px")};

        &:last-child {
            margin-right: 0;
        }
    }

    & > div:before {
        width: 13px;
        height: 13px;
        display: inline-block;
        content: "";
        margin-right: 5px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:after {
        display: block;
        content: "";
        position: absolute;
        bottom: -4px;
        left: calc(50% - 46px);
        background: url(${imgMenuBottom}) center center no-repeat;
        width: 92px;
        height: 4px;
    }

    //@media (max-width: 430px) {
    //    justify-content: initial;
    //}
`;

const MobileMenu = styled.div<{ isHidden: boolean }>`
    min-width: 300px;
    display: ${(p) => (p.isHidden ? "none" : "flex")};
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
`;

const MobileNav = styled.div`
    width: 100%;
    margin-top: 30px;
    font-size: 16px;
    line-height: 26px;
    color: ${(p) => p.theme.colorSecondary};
`;

const MobileNavButton = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 12px 5px;
    cursor: pointer;

    &:after {
        position: absolute;
        right: 0;
        width: 24px;
        height: 24px;
        display: inline-block;
        content: "";
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(${imgIconArrow});
    }

    &:before {
        width: 12px;
        height: 12px;
        display: inline-block;
        content: "";
        margin-right: 7px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        filter: hue-rotate(120deg) saturate(7);
    }

    &.logout {
        margin: 32px 0;

        &:before {
            filter: none;
        }

        &:after {
            display: none;
        }
    }
`;

const MobileMenuButton = styled.div<{ isActive: boolean }>`
    width: 24px;
    height: 24px;
    background: url(${(p) => (!p.isActive ? imgIconMenu : imgIconMenuClose)})
        center no-repeat;
    cursor: pointer;
`;

const S = styled.div`
    width: 25px;

    @media (max-width: ${(props) => props.theme.mediaL}) {
        width: 12px;
    }
`;

const ProgressSpace = styled.div`
    width: 40px;

    @media (max-width: ${(props) => props.theme.mediaL}) {
        width: 12px;
    }

    @media (max-width: ${(props) => props.theme.mediaM}) {
        width: 45px;
    }
    @media (max-width: ${(props) => props.theme.mediaS}) {
        display: none;
    }
`;

const NavBar = (): JSX.Element => {
    const { account } = useWeb3React<Web3Provider>();
    const dialog = useModalDialog();
    const { deactivate } = useWeb3React<Web3Provider>();
    const [mobileMenu, setMobileMenu] = useState<boolean>(false);
    const hideProgress =
        new Date() >=
        new Date("Wed Dec 08 2021 23:00:00 GMT+0300 (Moscow Standard Time)");

    const isL = useMedia({ maxWidth: "960px" });
    const isM = useMedia({ maxWidth: "745px" });
    const isS = useMedia({ maxWidth: theme.mediaS });

    const enableGallery = ENABLE_GALLERY;
    const enableRoadmap = ENABLE_ROADMAP;

    useEffect(() => {
        if (!isS) {
            setMobileMenu(false);
        }
    }, [isS, setMobileMenu]);

    const goTo = useCallback(
        (path: string) => () => {
            setMobileMenu(false);
            navigate(path);
        },
        [setMobileMenu]
    );

    const handleMobileMenu = useCallback(() => {
        setMobileMenu(!mobileMenu);
    }, [mobileMenu, setMobileMenu]);

    const handleLogout = useCallback(() => {
        dialog({
            children: ConfirmationDialog,
        })
            .then(() => {
                setMobileMenu(false);
                deactivate();
            })
            .catch(() => setMobileMenu(false));
    }, [dialog, deactivate, setMobileMenu]);

    return (
        <Wrapper>
            <Container>
                <Logo onClick={goTo("/")} />
                <Space />
                {!isL && (
                    <>
                        {/* <NavButton onClick={goTo("/#buy")}>Get Cards</NavButton>
                        <S /> */}
                        <NavButton onClick={goTo("/#story")}>Story</NavButton>
                        <S />
                        {/* {enableGallery && (
                            <>
                                <NavButton onClick={goTo("/gallery")}>
                                    Gallery
                                </NavButton>
                                <S />
                            </>
                        )} */}
                        {enableRoadmap && (
                            <>
                                <NavButton onClick={goTo("/roadmap")}>
                                    Roadmap
                                </NavButton>
                                <S />
                            </>
                        )}
                    </>
                )}
                {!isM && !hideProgress && <Progress hide={hideProgress} />}
                <ProgressSpace />
                <WalletButton />
                {isS && (
                    <>
                        <S />
                        <MobileMenuButton
                            isActive={mobileMenu}
                            onClick={handleMobileMenu}
                        />
                    </>
                )}
            </Container>
            {isL && !isS && (
                <MobileBar scroll={enableGallery}>
                    {/* <NavButton className={"buynft"} onClick={goTo("/#buy")}>
                        Get Cards
                    </NavButton> */}
                    <NavButton className={"story"} onClick={goTo("/#story")}>
                        Story
                    </NavButton>
                    {/* {enableGallery && (
                        <NavButton
                            className={"gallery"}
                            onClick={goTo("/gallery")}
                        >
                            Gallery
                        </NavButton>
                    )} */}
                    {enableRoadmap && (
                        <NavButton
                            className={"roadmap"}
                            onClick={goTo("/roadmap")}
                        >
                            Roadmap
                        </NavButton>
                    )}
                </MobileBar>
            )}
            <MobileMenu isHidden={!mobileMenu}>
                {!hideProgress && <Progress hide={hideProgress} />}
                <MobileNav>
                    {/* <MobileNavButton
                        className={"buynft"}
                        onClick={goTo("/#buy")}
                    >
                        Get Cards
                    </MobileNavButton> */}
                    <MobileNavButton
                        className={"story"}
                        onClick={goTo("/#story")}
                    >
                        Story
                    </MobileNavButton>
                    {/* {enableGallery && (
                        <MobileNavButton
                            className={"gallery"}
                            onClick={goTo("/gallery")}
                        >
                            Gallery
                        </MobileNavButton>
                    )} */}
                    {enableRoadmap && (
                        <MobileNavButton
                            className={"roadmap"}
                            onClick={goTo("/roadmap")}
                        >
                            Roadmap
                        </MobileNavButton>
                    )}
                    {account && (
                        <MobileNavButton
                            className={"logout"}
                            onClick={handleLogout}
                        >
                            Disconnect wallet
                        </MobileNavButton>
                    )}
                </MobileNav>
            </MobileMenu>
        </Wrapper>
    );
};

export default NavBar;
