import styled from "styled-components";

export const DialogTitle = styled.div`
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: ${(props) => props.theme.colorPrimary};
    margin: 10px 0 30px 0;
    ${(props: any) => props["data-type"] === "center" && `text-align: center`};
`;
