import * as React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import imgLogo from "../static/footer-logo.svg";
import imgLogoCryptomeria from "../static/cryptomeria.svg";
import imgInstagram from "../static/icons/1.svg";
import imgInstagramA from "../static/icons/6.svg";
import iconTwitter from "../static/icons/icon-twitter.svg";
import iconTwitterA from "../static/icons/icon-twitter-a.svg";
import iconDiscord from "../static/icons/icon-discord.svg";
import iconDiscordA from "../static/icons/icon-discord-a.svg";
import pdf from "../static/NFT_PURCHASE_TERMS_&_CONDITIONS_FOR_NFT_PURCHASE.pdf";
import {
    ENABLE_PROVENANCE,
    PRE_RELEASE,
    useGame,
} from "../contexts/GameContext";
import useMedia from "use-media";

const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 90px;
    background: #1e1e1e;
    padding: 0 30px;
    box-sizing: border-box;
    border-top: 1px solid #642c6f;
    flex-shrink: 0;

    @media (max-width: 980px) {
        height: 130px;
    }

    @media (max-width: 760px) {
        height: initial;
        padding: 25px 10px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1190px;
    height: inherit;

    @media (max-width: 760px) {
        flex-direction: column;
        justify-content: initial;
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 980px) {
        flex-direction: column;
    }

    @media (max-width: 760px) {
        flex-direction: row;
        margin-bottom: 17px;
    }
`;

const Logo = styled.div`
    width: 154px;
    height: 30px;
    flex-shrink: 0;
    background: url(${imgLogo}) center no-repeat;
    background-size: contain;

    @media (max-width: 980px) {
        width: 98px;
        height: 20px;
    }

    @media (max-width: 760px) {
        width: 100px;
        height: 20px;
    }
`;

const CreatedWrapper = styled.div`
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding-left: 22px;
    margin-left: 22px;

    @media (max-width: 1180px) {
        padding-left: 17px;
        margin-left: 17px;
    }

    @media (max-width: 980px) {
        border-left: none;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding-left: 0;
        padding-top: 12px;
        margin-left: 0;
        margin-top: 12px;
        width: 100%;
    }

    @media (max-width: 760px) {
        border: none;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 0 0 12px;
    }
`;

const CreatedText = styled.div`
    font-size: 10px;
    line-height: 140%;
    margin-bottom: 4px;
    color: #ed42da;
    font-weight: 700;

    @media (max-width: 760px) {
        margin-right: 12px;
        margin-bottom: 0;
    }
`;

const Terms = styled.div`
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.01em;
    flex-shrink: 0;
    margin-right: 15px;

    &:last-child {
        margin-right: 0;
    }

    & a {
        color: #ffffff;
        cursor: pointer;
    }

    @media (max-width: 760px) {
        margin: 0 0 17px 0;
        font-size: 10px;
        line-height: 12px;
        padding: 0;
        order: 3;
    }
`;

const Cryptomeria = styled.div`
    width: 110px;
    height: 20px;
    background: url(${imgLogoCryptomeria}) center no-repeat;
    background-size: contain;

    @media (max-width: 980px) {
        width: 83px;
        height: 15px;
    }
`;

const Address = styled.div`
    font-style: normal;
    font-weight: normal;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #ffffff;
    text-align: center;
    max-width: 300px;
    font-weight: 400;
    word-break: break-all;
    display: flex;
    flex-direction: column;

    & span {
        color: ${(props) => props.theme.colorSecondary};
    }

    @media (max-width: 1180px) {
        font-size: 10px;
        max-width: 150px;
    }

    @media (max-width: 760px) {
        max-width: 100%;
        &:empty {
            display: none;
        }
    }
`;

const Socials = styled.div`
    display: flex;

    @media (max-width: 760px) {
        order: 2;
        margin-bottom: 17px;
    }

    & > a {
        width: 40px;
        height: 40px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 8px;

        &:hover {
            background-color: rgba(237, 66, 218, 0.1);
            border-color: rgba(237, 66, 218, 0.12);
            cursor: pointer;
        }

        &:last-child {
            margin-right: 0;
        }

        @media (max-width: 1180px) {
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    @media (max-width: 760px) {
        margin-top: 0;
        & > a {
            width: 30px;
            height: 30px;
            background-size: 15px;
        }
    }
`;

const Twitter = styled.a`
    background-image: url(${iconTwitter});
    &:hover {
        background-image: url(${iconTwitterA});
    }
`;

const Discord = styled.a`
    background-image: url(${iconDiscord});
    &:hover {
        background-image: url(${iconDiscordA});
    }
`;

const Instagram = styled.a`
    background-image: url(${imgInstagram});
    background-size: cover; /* Добавляем установку размера фонового изображения */

    &:hover {
        background-image: url(${imgInstagramA});
    }
`;

const LinksWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;

    @media (max-width: 1180px) {
        flex-direction: row-reverse;
        width: 45%;
    }

    @media (max-width: 1080px) {
        width: 47.5%;
    }

    @media (max-width: 980px) {
        width: 61%;
    }

    @media (max-width: 760px) {
        width: 100%;
        flex-direction: column-reverse;
    }
`;

const TermsWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 760px) {
        width: 100%;
        flex-direction: column-reverse;
    }
`;

function AddressBox() {
    const etherscan =
        "https://lineascan.build/address/0x15f0bf5ff3f5aa1a3ff10120c1da669b80309642";

    return (
        <Address>
            Verified smart contract address:{" "}
            <span>
                <a href={etherscan} target="_blank">
                    0x15f0bf5ff3f5aa1a3ff10120c1da669b80309642
                </a>
            </span>
        </Address>
    );
}

const Footer = (): JSX.Element => {
    const isS = useMedia({ maxWidth: "760px" });

    return (
        <Container>
            <Wrapper>
                <LogoWrapper>
                    <Logo />
                    <CreatedWrapper>
                        <CreatedText>Created by</CreatedText>
                        <a target="_blank" href="https://cryptomerialabs.com/">
                            <Cryptomeria />
                        </a>
                    </CreatedWrapper>
                </LogoWrapper>
                {/* {!isS && <AddressBox />} */}
                <LinksWrapper>
                    <Socials>
                        <Twitter
                            href={"https://twitter.com/universesatoshi"}
                            target={"_blank"}
                        />
                        <Discord
                            href={"https://discord.com/invite/gJgEyAHw5p"}
                            target={"_blank"}
                        />
                        <Instagram
                            href={
                                "https://satoshi-universe.gitbook.io/satoshi-universe-lore/"
                            }
                            target={"_blank"}
                        />
                    </Socials>
                    <TermsWrapper>
                        {ENABLE_PROVENANCE && (
                            <Terms>
                                <a onClick={() => navigate("/provenance")}>
                                    Provenance
                                </a>
                            </Terms>
                        )}
                        <Terms>
                            <a href={pdf} target="_blank">
                                Terms and Conditions
                            </a>
                        </Terms>
                        <Terms>
                            <a onClick={() => navigate("/privacy")}>
                                Privacy Policy
                            </a>
                        </Terms>
                    </TermsWrapper>
                </LinksWrapper>
                {/* {isS && <AddressBox />} */}
            </Wrapper>
        </Container>
    );
};

export default Footer;
