import * as React from "react";
import styled from "styled-components";
import { GameStates, useGame } from "../contexts/GameContext";
import { Loading } from "./common/Loading";
import { formatNumber } from "../utils";
import imgProgressBg from "../static/progress-bg.svg";
import OldProgress from "./OldProgress";

const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 300px;
    color: ${(props) => props.theme.colorPrimary};
    box-sizing: border-box;
    padding: 10px;
    border-image-slice: 25 25 25 25 fill;
    border-image-width: 20px 20px 20px 20px;
    border-image-repeat: stretch stretch;
    border-image-source: url(${imgProgressBg});

    &.embedded {
        border: none;
        padding: 8px 0;
    }
`;

const Bar = styled.div`
    position: relative;
    height: 4px;
    width: 100%;
    margin-top: 5px;
    background: #111;
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
`;

const Line = styled.div`
    position: absolute;
    height: 4px;
    min-width: 4px;
    border-radius: 8px;
    background: ${(props) => props.theme.colorPrimary};
    transition: width 0.5s ease;
`;

const Info = styled.div`
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    font-feature-settings: "pnum" on, "lnum" on;
    box-sizing: border-box;
    margin-left: 10px;
`;

const Values = styled.div`
    display: flex;
    justify-content: space-between;

    span > span {
        color: rgba(17, 142, 140, 1);
    }
`;

type ProgressData = {
    maxTokenThreshold: number;
    progressTokens: number;
    progressValue: number;
    progressEth: number;
};

const Progress = ({
    embedded,
    hide,
}: {
    embedded?: boolean;
    hide?: boolean;
}) => {
    const { nft, game, currentState } = useGame();

    const [progressData, setProgressData] = React.useState<ProgressData>({
        maxTokenThreshold: 0,
        progressTokens: 0,
        progressValue: 0,
        progressEth: 0,
    });

    // дев: перетащил if (hide) {return null;} в useEffect, чтобы работало
    React.useEffect(() => {
        (async () => {
            if (hide) {
                return null;
            }
            if (game) {
                const progressTokens = Number((await nft?.totalSupply()) || 0);
                let maxTokenThreshold = 0;

                if (
                    currentState === GameStates.SELLING ||
                    currentState === GameStates.WHITELISTED
                ) {
                    maxTokenThreshold = Number(
                        game?.phases[game.phases.length - 1].threshold || 0
                    );
                } else {
                    maxTokenThreshold = progressTokens;
                }

                const progressValue =
                    (progressTokens / maxTokenThreshold) * 100;
                const progressEth = Number(game?.currentPhase?.price || 0.08);
                setProgressData({
                    maxTokenThreshold,
                    progressTokens,
                    progressValue,
                    progressEth,
                });
            }
        })();
    }, [nft, game, currentState]);

    // Render New navbar
    return (
        <Container className={embedded ? "embedded" : ""}>
            <Loading state={!game} style={{ margin: "15px 0" }}>
                <Info>
                    <Values>
                        <span>{progressData.progressEth} ETH</span>
                        <span>
                            {progressData.progressTokens} /{" "}
                            <span>
                                {formatNumber(progressData.maxTokenThreshold)}{" "}
                                NFTs
                            </span>
                        </span>
                    </Values>
                    <Bar>
                        <Line
                            style={{
                                width: `${progressData.progressValue.toFixed(
                                    0
                                )}%`,
                            }}
                        />
                    </Bar>
                </Info>
            </Loading>
        </Container>
    );
};

export default Progress;
