import { BigNumber, ethers, utils } from "ethers";
import React, { useCallback, useEffect, useState } from "react";
import { PropsWithChildren } from "react";
import { Web3Provider } from "@ethersproject/providers";

import abiSellingController from "../contracts/SellingController.sol/SellingController.json";
import abiGameController from "../contracts/GameController.sol/GameController.json";
import abiNFT from "../contracts/NFT.sol/NFT.json";
// кор: тут нужна будет корректировка контрактов
import { SellingController } from "../contracts/types/SellingController";
import { GameController } from "../contracts/types/GameController";
import { NFT } from "../contracts/types/NFT";
import { formatEther } from "ethers/lib/utils";
import {
    DEFAULT_CHAIN_ID,
    DEFAULT_PROVIDER,
    GAME_CONTROLLER_ADDRESSES,
    ProofMapping,
    RPC_URLS,
    SELLING_CONTROLLER_ADDRESSES,
    WhitelistProofMapping,
} from "../constants/contractAddresses";
import { useEagerConnect, useInactiveListener } from "../hooks/hooks";
import { useWeb3React } from "@web3-react/core";

export const PRE_RELEASE: boolean = process.env.PRE_RELEASE! === "true";
export const PROVENANCE_JSON_BLOCKS: boolean =
    process.env.PROVENANCE_JSON_BLOCKS! === "true";
export const ENABLE_ROADMAP: boolean = process.env.ENABLE_ROADMAP! === "true";
export const ENABLE_PROVENANCE: boolean =
    process.env.ENABLE_PROVENANCE! === "true";
export const ENABLE_GALLERY: boolean = process.env.ENABLE_GALLERY! === "true";
export const STAGING: boolean = process.env.STAGING! === "true";

// interface ProofMapping {
//     // Token id : ...
//     [key: string]: {ipfsUri: string; merkleProof: Array<string>; dna: number};
// }

//  interface WhitelistProofMapping {
//     // user address : ..
//     [key: string]: {cap: number; merkleProof: Array<string>, partner: boolean};
// }

// const DEFAULT_CHAIN_ID = 1
// const DEFAULT_PROVIDER = (): ethers.providers.WebSocketProvider =>
// new ethers.providers.WebSocketProvider(`wss://mainnet.infura.io/ws/v3/8fcf8c7887bc42b3838861a96e1b6a65`);
// const GAME_CONTROLLER_ADDRESSES = "0x8E1dB865C777622e3d64D50589E036A8E09Cb2e8"
// const RPC_URLS = `https://mainnet.infura.io/v3/8fcf8c7887bc42b3838861a96e1b6a65`
// const SELLING_CONTROLLER_ADDRESSES = "0xA524FEee0Bb8e1FE759e281422e153DcD784564d"

const STATIC_PHASE = {
    price: "0.08",
    threshold: "6000",
};
const NFTMock = {
    removeAllListeners: () => null,
    on: () => null,
    totalSupply: async () => 0,
};
interface Game {
    currentPhase?: NFTReading;
    phases: Array<NFTReading>;
}

interface ContextItems {
    game?: Game;
    nft?: NFT;
    sellingController?: SellingController;
    gameController?: GameController;
    proofs?: ProofMapping;
    whitelisted?: WhitelistedEntry;
    currentState: GameStates;
    provider?: Provider;
    cachedChainId: number;
}

const GameContext = React.createContext<ContextItems>( // A proxy object allows to completely define the behavior of a proxied object
    // In this case if a parent component wont be wrapped in GameContextProvider
    // Then any calls made to access the context values will result in
    // Being cached by the proxy object and display the errors bellow
    new Proxy({} as ContextItems, {
        apply: () => {
            // throw new Error(
            //     "You must wrap your component in an GameContextProvider"
            // );
        },
        get: () => {
            // throw new Error(
            //     "You must wrap your component in an GameContextProvider"
            // );
        },
    })
);

async function fetchCardRevealProofs(
    chainId: string,
    contractAddress: string
): Promise<ProofMapping | undefined> {
    try {
        const mapping = await import(
            `../static/proofs/generated/${chainId}/${contractAddress}/gen_card_info_proofs.json`
        );
        if (mapping) {
            return mapping as unknown as ProofMapping;
        }
    } catch (error) {
        return undefined;
    }
}
async function whitelistProofs(
    chainId: string,
    contractAddress: string
): Promise<WhitelistProofMapping | undefined> {
    try {
        const mapping = await import(
            `../static/proofs/generated/${chainId}/${contractAddress}/whitelist-proofs.json`
        );
        if (mapping) {
            return mapping as unknown as WhitelistProofMapping;
        }
    } catch (error) {
        return undefined;
    }
}

type WhitelistedEntry = {
    approved: boolean;
    allowance: BigNumber;
    startingCap: BigNumber;
    proofs: Array<string>;
    partner: boolean;
    totalWhitelistCap: number;
};

export interface NFTReading {
    price: string;
    threshold: string;
}

async function phaseInfo(
    sellingController: SellingController
): Promise<NFTReading[]> {
    if (PRE_RELEASE) {
        return [STATIC_PHASE];
    }
    const totalPhases = (await sellingController.getPhaseCount()).toNumber();
    const res = await Promise.all(
        Array(totalPhases)
            .fill(0)
            .map(async (_, i) => ({
                price: formatEther(
                    await sellingController.getPhasePrice(i)
                ).toString(),
                threshold: (
                    await sellingController.getPhaseThreshold(i)
                ).toString(),
            }))
    );
    return res;
}
async function whitelistInfo(
    chainId: string,
    sellingController: SellingController,
    account: string
): Promise<WhitelistedEntry | undefined> {
    const whitelistEnabled = await sellingController.whitelistEnabled();
    let res: WhitelistedEntry | undefined = undefined;
    if (whitelistEnabled) {
        const whitelistMapping = await whitelistProofs(
            chainId,
            sellingController.address
        );
        const entryLowercase =
            whitelistMapping && whitelistMapping[account.toLowerCase()];
        const entryBasic = whitelistMapping && whitelistMapping[account];
        const entryInMapping = entryLowercase || entryBasic;

        if (account && entryInMapping) {
            const whitelistCap = await sellingController.getWhitelistCap();
            res = {
                ...(await sellingController.getWhitelistEntry(account)),
                proofs: entryInMapping.merkleProof,
                startingCap: BigNumber.from(entryInMapping.cap),
                partner: entryInMapping.partner,
                totalWhitelistCap: whitelistCap,
            };

            if (res.approved === false) {
                res = {
                    startingCap: BigNumber.from(entryInMapping.cap),
                    allowance: BigNumber.from(entryInMapping.cap),
                    approved: true,
                    proofs: entryInMapping.merkleProof,
                    partner: entryInMapping.partner,
                    totalWhitelistCap: whitelistCap,
                };
            }
        } else {
            res = {
                startingCap: BigNumber.from(0),
                allowance: BigNumber.from(0),
                approved: false,
                proofs: [],
                partner: false,
                totalWhitelistCap: 0,
            };
        }
    }
    return res;
}

export enum GameStates {
    WHITELISTED,
    SELLING,
    SELLING_STOPPED,
    REVEAL,
    UPGRADE,
}
type Provider =
    | ethers.providers.Web3Provider
    | ethers.providers.WebSocketProvider;

function GameContextProvider({
    children,
}: PropsWithChildren<unknown>): React.ReactElement {
    // --------------- Wallet data start --------------- //
    const triedEager = useEagerConnect();
    useInactiveListener(!triedEager);
    // --------------- Wallet data end --------------- //
    const { account, library, chainId } = useWeb3React<Web3Provider>();

    const [provider, setProvider] = useState<Provider>();
    const [cachedChainId, setCachedChainId] =
        useState<number>(DEFAULT_CHAIN_ID);
    const [game, setGame] = useState<Game>();
    const [nft, setNFT] = useState<NFT>();
    const [currentState, setCurrentState] = useState<GameStates>(
        GameStates.SELLING
    );
    const [gameController, setGameController] = useState<GameController>();
    const [sellingController, setSellingController] =
        useState<SellingController>();
    const [proofs, setProofs] = useState<ProofMapping>();

    // undefined: no whitelist
    // else: val.approved defines if the user's whitelisted or not
    const [whitelisted, setWhitelisted] = useState<WhitelistedEntry>();

    const refreshGame = useCallback(async (): Promise<{
        game: Game | undefined;
        nft: NFT | undefined;
        selling: SellingController | undefined;
        gameController: GameController | undefined;
        revealProofsLocal: ProofMapping | undefined;
        currentState: GameStates;
        chainId: number;
        whitelistedLocal: WhitelistedEntry | undefined;
        defaultProviderInternal: Provider;
    }> => {
        const defaultProviderInternal = library || DEFAULT_PROVIDER();
        const chainId = (await defaultProviderInternal.getNetwork()).chainId;
        if (PRE_RELEASE) {
            const sellingMock = new Object() as SellingController;
            const phases = await phaseInfo(sellingMock);
            return {
                currentState: GameStates.SELLING,
                game: {
                    phases,
                    currentPhase: phases[0],
                },
                nft: NFTMock as unknown as NFT,
                selling: sellingMock,
                gameController: undefined,
                revealProofsLocal: undefined,
                whitelistedLocal: undefined,
                chainId: 1,
                defaultProviderInternal,
            };
        }

        let game: Game | undefined = undefined;
        let selling: SellingController | undefined = undefined;
        let nft: NFT | undefined = undefined;
        let gameController: GameController | undefined = undefined;
        let revealProofsLocal: ProofMapping | undefined = undefined;
        let whitelistedLocal: WhitelistedEntry | undefined = undefined;
        let gameState = GameStates.SELLING;

        if (
            defaultProviderInternal &&
            Object.keys(SELLING_CONTROLLER_ADDRESSES).includes(
                chainId.toString()
            )
        ) {
            // Get the selling controller
            const sellingController = new ethers.Contract(
                SELLING_CONTROLLER_ADDRESSES[chainId],
                abiSellingController.abi,
                defaultProviderInternal
            ) as SellingController;

            if (await sellingController.isSellingStopped()) {
                gameState = GameStates.SELLING_STOPPED;
            } else if (await sellingController.whitelistEnabled()) {
                gameState = GameStates.WHITELISTED;
            }

            // gather phase information
            const phases = await phaseInfo(sellingController);
            const currentPhase = phases[await sellingController.phaseIndex()];
            // Get the NFT token
            const nftToken = new ethers.Contract(
                await sellingController.nftToken(),
                abiNFT.abi,
                defaultProviderInternal
            ) as NFT;

            // Check for whitelist
            if (account) {
                whitelistedLocal = await whitelistInfo(
                    chainId.toString(),
                    sellingController,
                    account
                );
            }

            if (
                gameState === GameStates.SELLING_STOPPED &&
                Object.keys(GAME_CONTROLLER_ADDRESSES).includes(
                    chainId.toString()
                )
            ) {
                gameController = new ethers.Contract(
                    GAME_CONTROLLER_ADDRESSES[chainId].address,
                    abiGameController.abi,
                    defaultProviderInternal
                ) as GameController;
                if (await gameController.isCardRevealEnabled()) {
                    // Try to fetch the reveal proofs.
                    const reveals = await fetchCardRevealProofs(
                        chainId.toString(),
                        gameController.address
                    );
                    if (reveals) {
                        revealProofsLocal = reveals;
                        // Only set the game state if we have the reveal proofs
                        gameState = GameStates.REVEAL;
                    }
                }

                if (await gameController.isCardUpgradeEnabled()) {
                    gameState = GameStates.UPGRADE;
                }
            }

            nft = nftToken;
            selling = sellingController;
            game = {
                phases: phases,
                currentPhase,
            };
        }
        return {
            defaultProviderInternal,
            currentState: gameState,
            game,
            nft,
            selling,
            gameController,
            revealProofsLocal,
            chainId,
            whitelistedLocal,
        };
    }, [library, account, chainId, DEFAULT_PROVIDER]);

    useEffect(() => {
        let cancel = false;
        void refreshGame().then(async (e) => {
            if (cancel) return;

            // Eliminate an issue where a chain switch while executing refreshGame()
            // can cause an update of the previous state. (Eliminate race condition error).
            setGame(e.game);
            setNFT(e.nft);
            setSellingController(e.selling);
            setGameController(e.gameController);
            setProofs(e.revealProofsLocal);
            setWhitelisted(e.whitelistedLocal);
            setCurrentState(e.currentState);
            setCachedChainId(e.chainId);
            setProvider(e.defaultProviderInternal);
        });

        // Cleanup function that will be called on
        // 1. Unmount
        // 2. Dependency Array Change
        return () => {
            cancel = true;
        };
    }, [library, account, chainId]);

    useEffect(() => {
        if (nft && sellingController && account) {
            nft.on(
                {
                    topics: [utils.id("Transfer(address,address,uint256)")],
                    fromBlock: "latest",
                },
                async () => {
                    const phases = await phaseInfo(sellingController);
                    setGame({
                        phases,
                        currentPhase:
                            phases[await sellingController.phaseIndex()],
                    });

                    if (account && chainId) {
                        const whitelistedLocal = await whitelistInfo(
                            chainId.toString(),
                            sellingController,
                            account
                        );
                        setWhitelisted(whitelistedLocal);
                    }
                }
            );
        }
        return () => {
            nft?.removeAllListeners();
        };
    }, [nft, sellingController, account, chainId]);

    /*
    const alreadyWitchedNetworkOnce = React.useRef(false);
    useEffect(() => {
        if (library && library.provider && account !== undefined) {
            const rawEthReq = library.provider.request;
            if (
                rawEthReq &&
                chainId !== DEFAULT_CHAIN_ID &&
                !alreadyWitchedNetworkOnce.current
            ) {
                const f = async () => {
                    const chainIdToSwitchTo = `0x${DEFAULT_CHAIN_ID}`;
                    try {
                        await rawEthReq({
                            method: "wallet_switchEthereumChain",
                            params: [{chainId: chainIdToSwitchTo}],
                        });
                    } catch (switchError) {
                        // This error code indicates that the chain has not been added to MetaMask.
                        // кор: свитч сети!
                        if ((switchError as any).code === 4902) {
                            try {
                                await rawEthReq({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: chainIdToSwitchTo,
                                            rpcUrl: RPC_URLS[DEFAULT_CHAIN_ID],
                                        },
                                    ],
                                });
                            } catch (addError) {
                                // handle "add" error
                            }
                        }
                        // handle other "switch" errors
                    }
                    alreadyWitchedNetworkOnce.current = true;
                };
                void f();
            }
        }
    }, [chainId, library, account]);
*/
    return (
        <GameContext.Provider
            value={{
                game,
                nft,
                sellingController,
                gameController,
                currentState,
                proofs,
                whitelisted,
                provider,
                cachedChainId,
            }}
        >
            {children}
        </GameContext.Provider>
    );
}

const useGame = (): ContextItems => React.useContext(GameContext);

export { GameContextProvider, useGame };
