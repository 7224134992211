import * as React from "react";
import { CSSProperties, PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 5px;
    background: #111111;
    border-radius: 2px;
    margin: 20px 0;
    overflow: hidden;
`;

const Bar = styled.div`
    --width: 120px;
    position: absolute;
    height: inherit;
    width: var(--width);
    background: ${(p) => p.theme.colorSecondary};
    border-radius: inherit;
    animation: spinner 1.2s linear infinite;

    @keyframes spinner {
        from {
            left: calc(var(--width) - var(--width) * 2);
        }
        to {
            left: 100%;
        }
    }
`;

export function Loading({
    state = false,
    children,
    style,
}: PropsWithChildren<{
    state?: boolean;
    style?: CSSProperties;
}>) {
    if (!state) {
        return <>{children}</> || null;
    }
    return (
        <Container style={style}>
            <Bar />
        </Container>
    );
}
