import { useState, useEffect, useLayoutEffect } from "react";
import useMedia from "use-media";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../components/dialogs/ConnectDialog";
import { BigNumber } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import React from "react";
import { useTimer } from "react-use-precision-timer";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { ConnectorNames } from "../constants/contractAddresses";
import {
    Attribute,
    CARD_CHUNK,
    fetchCards,
    Filters,
    Hero,
    IncludeHidden,
    Offset,
    Owner,
    Requester,
} from "../utils";
import { GameStates, useGame } from "../contexts/GameContext";
import theme from "../theme";
import { clueList } from "../common/clueList";

// Web3-react `disconnect` is kind of broken -- https://github.com/NoahZinsmeister/web3-react/issues/228
// Therefore we do some dirty hacks by storing disconnect state in the localstorage
enum LocalStorageData {
    Disconnected = "disconnected",
    Wallet = "wallet",
}

// Wrapper around `deactivate`
export function useDisconnect(): () => void {
    const { deactivate } = useWeb3React();
    return () => {
        localStorage.setItem(LocalStorageData.Disconnected, "true");
        localStorage.removeItem(LocalStorageData.Wallet);
        deactivate();
    };
}

// Wrapper around `activate`
export function useConnect(): (
    connector: AbstractConnector,
    walletType: ConnectorNames
) => Promise<void> {
    const { activate } = useWeb3React();
    return async (connector: AbstractConnector, walletType: ConnectorNames) => {
        localStorage.removeItem(LocalStorageData.Disconnected);
        localStorage.setItem(LocalStorageData.Wallet, walletType);
        await activate(connector);
    };
}

export function useWalletType(): () => ConnectorNames | undefined {
    return () => {
        if (typeof window !== "undefined") {
            const wallet = localStorage.getItem(LocalStorageData.Wallet);
            if (wallet) {
                return wallet as ConnectorNames;
            }
        }
    };
}

export function useClues(hero: Hero): Attribute[] | undefined {
    const clues = React.useMemo(() => {
        return hero.attributes?.filter(
            (a) =>
                clueList.find(
                    (i) =>
                        i.title.toLocaleLowerCase() ===
                        a.value.toLocaleLowerCase()
                ) && a.trait_type === undefined
        );
    }, [hero]);
    return clues;
}

export function useEagerConnect(): boolean {
    const { activate, active } = useWeb3React();

    const [tried, setTried] = useState(false);

    useEffect(() => {
        injected.isAuthorized().then((isAuthorized: boolean) => {
            const isDisconnected =
                localStorage.getItem(LocalStorageData.Disconnected) === "true";
            const hadConnected = localStorage.getItem(LocalStorageData.Wallet);
            if (isAuthorized && !isDisconnected && !!hadConnected) {
                activate(injected, undefined, true).catch(() => {
                    setTried(true);
                });
            } else {
                setTried(true);
            }
        });
    }, []); // intentionally only running on mount (make sure it's only mounted once :))

    // if the connection worked, wait until we get confirmation of that to flip the flag
    useEffect(() => {
        if (!tried && active) {
            setTried(true);
        }
    }, [tried, active]);

    return tried;
}

export function useInactiveListener(suppress = false): void {
    const { active, error, activate } = useWeb3React();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { ethereum } = window as any;
        if (ethereum && ethereum.on && !active && !error && !suppress) {
            const handleConnect = () => {
                console.log("Handling 'connect' event");
                activate(injected);
            };
            const handleChainChanged = (chainId: string | number) => {
                console.log(
                    "Handling 'chainChanged' event with payload",
                    chainId
                );
                activate(injected);
            };
            const handleAccountsChanged = (accounts: string[]) => {
                console.log(
                    "Handling 'accountsChanged' event with payload",
                    accounts
                );
                if (accounts.length > 0) {
                    activate(injected);
                }
            };
            const handleNetworkChanged = (networkId: string | number) => {
                console.log(
                    "Handling 'networkChanged' event with payload",
                    networkId
                );
                activate(injected);
            };

            ethereum.on("connect", handleConnect);
            ethereum.on("chainChanged", handleChainChanged);
            ethereum.on("accountsChanged", handleAccountsChanged);
            ethereum.on("networkChanged", handleNetworkChanged);

            return () => {
                if (ethereum.removeListener) {
                    ethereum.removeListener("connect", handleConnect);
                    ethereum.removeListener("chainChanged", handleChainChanged);
                    ethereum.removeListener(
                        "accountsChanged",
                        handleAccountsChanged
                    );
                    ethereum.removeListener(
                        "networkChanged",
                        handleNetworkChanged
                    );
                }
            };
        }
    }, [active, error, suppress, activate]);
}

export function useBalance(): BigNumber | undefined {
    const { account, library, chainId } = useWeb3React<Web3Provider>();
    const [balance, setBalance] = React.useState<BigNumber>();
    const update = React.useCallback(() => {
        if (!!account && !!library) {
            let stale = false;

            library
                .getBalance(account)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((balance) => {
                    if (!stale) {
                        setBalance(balance);
                    }
                })
                .catch(() => {
                    if (!stale) {
                        setBalance(undefined);
                    }
                });

            return () => {
                stale = true;
                setBalance(undefined);
            };
        }
    }, [account, library, chainId]);

    // Update wallet passively every 15 seconds
    // TODO: Is there a better way of doing this?
    // дев: закомментил строку ниже
    // useTimer({delay: 15000, callback: update, startImmediately: true});
    // Update wallet reactively when parameters change
    React.useEffect(() => {
        update();
    }, [account, library, chainId]);

    return balance;
}

// -------------------- Gallery filtering -------------------- //

type FilterId = string;
export type SingleFilter = {
    id: FilterId;
    query: FilterId;
    label: string;
    selected: boolean;
};

const HeroFilters: Array<SingleFilter> = [
    {
        id: "1",
        query: "The Slacker",
        label: "Slacker",
        selected: false,
    },
    {
        id: "2",
        query: "The Geek",
        label: "Geek",
        selected: false,
    },
    {
        id: "3",
        query: "The Hacker",
        label: "Hacker",
        selected: false,
    },
    {
        id: "4",
        query: "The Freak",
        label: "Freak",
        selected: false,
    },
    // TODO Enable the filter after one of the endings is found
    // {
    //     id: "5",
    //     query: [{value: "Vitalik Buterin", trait_type: "Hero Type"}],
    //     label: "Vitalik Buterin",
    //     selected: false,
    // },
    // {
    //     id: "6",
    //     query: [{value: "CR419-WR19H7", trait_type: "Hero Type"}],
    //     label: "CR419-WR19H7",
    //     selected: false,
    // },
    // {
    //     id: "7",
    //     query: [{value: "Satoshi Nakamoto - body", trait_type: "Hero Type"}],
    //     label: "Satoshi Nakamoto - body",
    //     selected: false,
    // },
    // {
    //     id: "8",
    //     query: [{value: "Satoshi Nakamoto - brain", trait_type: "Hero Type"}],
    //     label: "Satoshi Nakamoto - brain",
    //     selected: false,
    // },
];
const LocationFilters: Array<SingleFilter> = [
    {
        selected: false,
        label: "The Earth - daytime",
        id: "1",
        query: "The Earth - daytime",
    },
    {
        selected: false,
        label: "The Earth - dawn",
        id: "2",
        query: "The Earth - dawn",
    },
    {
        selected: false,
        label: "The Earth - nighttime",
        id: "3",
        query: "The Earth - nighttime",
    },
    {
        selected: false,
        label: "The Earth - dusk",
        id: "4",
        query: "The Earth - dusk",
    },
    {
        selected: false,
        label: "Mars - dust storm",
        id: "5",
        query: "Mars - dust storm",
    },
    {
        selected: false,
        label: "Mars",
        id: "6",
        query: "Mars",
    },
    {
        selected: false,
        label: "The Moon - dark side",
        id: "7",
        query: "The Moon - dark side",
    },
    {
        selected: false,
        label: "The Moon - near side",
        id: "8",
        query: "The Moon - near side",
    },
    {
        selected: false,
        label: "Sky City",
        id: "9",
        query: "Sky City",
    },
    {
        selected: false,
        label: "Sky City - apocalyptic",
        id: "10",
        query: "Sky City - apocalyptic",
    },
];
const LevelFilters: Array<SingleFilter> = [
    {
        selected: false,
        label: "Hide and Seeker",
        id: "1",
        query: "Hide and Seeker",
    },
    {
        selected: false,
        label: "Treasure Hunter",
        id: "2",
        query: "Treasure Hunter",
    },
    {
        selected: false,
        label: "Crime Cracker",
        id: "3",
        query: "Crime Cracker",
    },
    {
        selected: false,
        label: "Godlike Genius",
        id: "4",
        query: "Godlike Genius",
    },
];
const ClotheFilters: Array<SingleFilter> = [
    {
        selected: false,
        label: "Regular",
        id: "1",
        query: "Regular",
    },
    {
        selected: false,
        label: "Black",
        id: "2",
        query: "Black",
    },
    {
        selected: false,
        label: "Digital",
        id: "3",
        query: "Digital",
    },
    {
        selected: false,
        label: "Duster",
        id: "4",
        query: "Duster",
    },
    {
        selected: false,
        label: "Khaki",
        id: "5",
        query: "Khaki",
    },
    {
        selected: false,
        label: "Ripped",
        id: "6",
        query: "Ripped",
    },
    {
        selected: false,
        label: "Steel",
        id: "7",
        query: "Steel",
    },
];
const EyeFilters: Array<SingleFilter> = [
    {
        selected: false,
        label: "Regular",
        id: "1",
        query: "Regular",
    },
    {
        selected: false,
        label: "Alien black",
        id: "2",
        query: "Alien black",
    },
    {
        selected: false,
        label: "Bored ape",
        id: "3",
        query: "Bored ape",
    },
    {
        selected: false,
        label: "CryptoPunk",
        id: "4",
        query: "CryptoPunk",
    },
    {
        selected: false,
        label: "Glowing blue",
        id: "5",
        query: "Glowing blue",
    },
    {
        selected: false,
        label: "Glowing red",
        id: "6",
        query: "Glowing red",
    },
    {
        selected: false,
        label: "Hypnotic",
        id: "7",
        query: "Hypnotic",
    },
    {
        selected: false,
        label: "Laser - black",
        id: "8",
        query: "Laser - black",
    },
    {
        selected: false,
        label: "Laser - blue",
        id: "9",
        query: "Laser - blue",
    },
    {
        selected: false,
        label: "Laser - green",
        id: "10",
        query: "Laser - green",
    },
    {
        selected: false,
        label: "Laser - red",
        id: "11",
        query: "Laser - red",
    },
    {
        selected: false,
        label: "Viper glasses",
        id: "12",
        query: "Viper glasses",
    },
];
const MouthFilters: Array<SingleFilter> = [
    {
        selected: false,
        label: "Regular",
        id: "1",
        query: "Regular",
    },
    {
        selected: false,
        label: "Angry",
        id: "2",
        query: "Angry",
    },
    {
        selected: false,
        label: "Grin",
        id: "3",
        query: "Grin",
    },
    {
        selected: false,
        label: "Smoking",
        id: "4",
        query: "Smoking",
    },
    {
        selected: false,
        label: "Mask",
        id: "5",
        query: "Mask",
    },
    {
        selected: false,
        label: "Smile",
        id: "6",
        query: "Smile",
    },
    {
        selected: false,
        label: "Spray",
        id: "7",
        query: "Spray",
    },
    {
        selected: false,
        label: "Tape",
        id: "8",
        query: "Tape",
    },
    {
        selected: false,
        label: "Wonder",
        id: "9",
        query: "Wonder",
    },
];
const ClueFilter: Array<SingleFilter> = [
    {
        selected: false,
        label: "First bitcoin transaction",
        id: "1",
        query: "First bitcoin transaction",
    },
    {
        selected: false,
        label: "Bitcointalk forums",
        id: "2",
        query: "Bitcointalk forums",
    },
    {
        selected: false,
        label: "Silk Road launch",
        id: "3",
        query: "Silk Road launch",
    },
    {
        selected: false,
        label: "184 billion BTC bug",
        id: "4",
        query: "184 billion BTC bug",
    },
    {
        selected: false,
        label: "Dogecoin CEO",
        id: "5",
        query: "Dogecoin CEO",
    },
    {
        selected: false,
        label: "Ethereum launch",
        id: "6",
        query: "Ethereum launch",
    },
    {
        selected: false,
        label: "Bitcoin whitepaper",
        id: "7",
        query: "Bitcoin whitepaper",
    },
    {
        selected: false,
        label: "Bitcoin Cash fork",
        id: "8",
        query: "Bitcoin Cash fork",
    },
    {
        selected: false,
        label: "Lightning Network launch",
        id: "9",
        query: "Lightning Network launch",
    },
    {
        selected: false,
        label: "Craig Wright",
        id: "10",
        query: "Craig Wright",
    },
    {
        selected: false,
        label: "Tesla BTC purchase",
        id: "11",
        query: "Tesla BTC purchase",
    },
    {
        selected: false,
        label: "Bitcoin pizza",
        id: "12",
        query: "Bitcoin pizza",
    },
    {
        selected: false,
        label: "Hash mask",
        id: "13",
        query: "Hash mask",
    },
    {
        selected: false,
        label: "Trezor Crowdfunding",
        id: "14",
        query: "Trezor Crowdfunding",
    },
    {
        selected: false,
        label: "Bitcoin Magazine",
        id: "15",
        query: "Bitcoin Magazine",
    },
];

export type FilterInfo = {
    heroFilters: Array<SingleFilter>;
    levelFilters: Array<SingleFilter>;
    locationFilters: Array<SingleFilter>;
    clotheFilters: Array<SingleFilter>;
    eyeFilters: Array<SingleFilter>;
    mouthFilters: Array<SingleFilter>;
    clueFilters: Array<SingleFilter>;
    clueShardFilters: Array<SingleFilter>;
};

export function useFilter(): {
    filters: FilterInfo;
    getDbQueryIds: (
        enabledFilterIds: Array<string>,
        absoluteFilters: Array<SingleFilter>
    ) => string[];
} {
    const transmutedFilters = React.useMemo(() => {
        const heroFilters = JSON.parse(JSON.stringify(HeroFilters));
        const levelFilters = JSON.parse(JSON.stringify(LevelFilters));
        const clueFilters = JSON.parse(JSON.stringify(ClueFilter));
        const clueShardFilters = JSON.parse(JSON.stringify(ClueFilter));
        const mouthFilters = JSON.parse(JSON.stringify(MouthFilters));
        const eyeFilters = JSON.parse(JSON.stringify(EyeFilters));
        const clotheFilters = JSON.parse(JSON.stringify(ClotheFilters));
        const locationFilters = JSON.parse(JSON.stringify(LocationFilters));
        return {
            heroFilters,
            levelFilters,
            locationFilters,
            clueFilters,
            clueShardFilters,
            mouthFilters,
            eyeFilters,
            clotheFilters,
        };
    }, []);

    const getDbQueryIds = (
        enabledFilterIds: Array<string>,
        absoluteFilters: Array<SingleFilter>
    ) => {
        const enabled = absoluteFilters.filter((e) =>
            enabledFilterIds.includes(e.id)
        );
        const query = enabled.reduce<Array<string>>((acc, i) => {
            return acc.concat(i.query);
        }, []);
        return query;
    };

    return { filters: transmutedFilters, getDbQueryIds };
}

export function useQueryCards(showUserSpecificCards: boolean): {
    handleLoadMore: (
        filter: Filters,
        fetchedCards: Array<Hero>
    ) => Promise<Hero[]>;
    handleFilter: (f: Filters) => Promise<Hero[]>;
    backendAlive: boolean;
    loading: boolean;
    totalCards: number;
    totalCardsThatCanBeDisplayed: number;
} {
    const { account } = useWeb3React<Web3Provider>();
    const { nft, currentState, cachedChainId } = useGame();
    const [loading, setLoading] = React.useState(true);
    const [backendAlive, setBackendAlive] = React.useState(
        !!process.env.BACKEND
    );

    // Used only when displaying cards before upgrades
    const [visibleToken, setVisibleToken] = React.useState<Array<number>>([]);
    const [totalCardsThatCanBeDisplayed, setTotalCardsThatCanBeDisplayed] =
        React.useState<number>(0);
    const [totalCards, setTotalCards] = React.useState<number>(0);

    const _handleFilterBackend = React.useCallback(
        async (
            offset: number,
            filter: Filters,
            extend?: Array<Hero>,
            recalculateTotalCards?: boolean
        ) => {
            let result = extend || [];
            setLoading(true);

            const alteredFilter: Filters & Owner & IncludeHidden = {
                ...filter,
                includeHidden: false,
            };

            if (showUserSpecificCards && account) {
                alteredFilter.owner = account;
                alteredFilter.includeHidden = true;
            }
            if (recalculateTotalCards) {
                try {
                    const totalCards = await Requester.getTotalCardsInGallery(
                        alteredFilter
                    );
                    setTotalCardsThatCanBeDisplayed(totalCards.data.data);

                    if (!showUserSpecificCards) {
                        setTotalCards(totalCards.data.data);
                    }
                    setBackendAlive(true);
                } catch (error) {
                    setBackendAlive(false);
                    console.log("Backend offline");
                }
            }
            try {
                const rawCards = await Requester.getGalleryCards(
                    alteredFilter,
                    offset
                );
                const res: Array<Hero> = rawCards.data.data.map((e) => ({
                    ...e.metadata,
                    tokenId: Number(e.tokenId),
                    dna: e.dna,
                }));
                result = result.concat(res);
                setBackendAlive(true);
            } catch (error) {
                setBackendAlive(false);
                console.log("Backend offline");
            }
            setLoading(false);
            return result;
        },
        [currentState, account]
    );

    const _handleFilterNoBackend = React.useCallback(
        async (
            offset: number,
            filter: Filters,
            extend?: Array<Hero>,
            recalculateTotalCards?: boolean
        ) => {
            let result = extend || [];
            if (nft) {
                setLoading(true);
                const tokenSlice = visibleToken.slice(
                    offset,
                    offset + CARD_CHUNK
                );
                const res = await fetchCards(nft, tokenSlice, cachedChainId);
                result = result.concat(res);
                setLoading(false);
            }
            return result;
        },
        [nft, visibleToken]
    );

    const _handleFilterReal = React.useMemo(() => {
        if (backendAlive) {
            return _handleFilterBackend;
        } else if (nft) {
            return _handleFilterNoBackend;
        } else {
            return async (
                offset: number,
                filter: Filters,
                extend?: Array<Hero>,
                recalculateTotalCards?: boolean
            ) => {
                return [];
            };
        }
    }, [
        currentState === GameStates.UPGRADE,
        nft,
        _handleFilterBackend,
        _handleFilterNoBackend,
        backendAlive,
    ]);

    const handleLoadMore = React.useCallback(
        async (filter: Filters, fetchedCards: Array<Hero>) => {
            return await _handleFilterReal(
                fetchedCards.length || 0,
                filter,
                fetchedCards
            );
        },
        [_handleFilterReal]
    );
    const handleFilter = React.useCallback(
        /// A wrapper so we don't spiral into infinite reloading
        (f: Filters) => {
            return _handleFilterReal(0, f, undefined, true);
        },
        [_handleFilterReal]
    );

    React.useEffect(() => {
        (async () => {
            if (nft && account && !backendAlive) {
                const balance = (await nft.balanceOf(account)).toNumber();
                setTotalCards(balance);
                const tokenIds = (
                    await Promise.all(
                        Array(balance)
                            .fill(0)
                            .map((_, i) => i)
                            .map((i) => nft.tokenOfOwnerByIndex(account, i))
                    )
                ).map((i) => Number(i));
                setVisibleToken(tokenIds);
                setTotalCardsThatCanBeDisplayed(tokenIds.length);
            }
        })();
    }, [account, nft, currentState, backendAlive]);

    React.useEffect(() => {
        (async () => {
            if (nft && account) {
                const balance = (await nft.balanceOf(account)).toNumber();
                setTotalCards(balance);
            }
        })();
    }, [account, nft]);

    React.useEffect(() => {
        (async () => {
            if (backendAlive) {
                try {
                    // Will throw if something wrong
                    await Requester.health();
                    setBackendAlive(true);
                } catch (error) {
                    setBackendAlive(false);
                }
            }
        })();
    }, [backendAlive]);

    return {
        handleLoadMore,
        handleFilter,
        backendAlive,
        loading,
        totalCardsThatCanBeDisplayed,
        totalCards,
    };
}

export const useWindowHeight = (): number => {
    const isS = useMedia({ maxWidth: theme.mediaS });
    const [size, setSize] = useState(0);

    useLayoutEffect(() => {
        function updateSize() {
            setSize(window.innerHeight);
        }
        window.addEventListener("resize", updateSize);
        updateSize();

        return () => window.removeEventListener("resize", updateSize);
    }, [isS]);

    return isS ? size - 300 : size - 170;
};
