import "@fontsource/raleway";
import "@fontsource/raleway/200.css";
import "@fontsource/raleway/400.css";
import "@fontsource/raleway/500.css";
import "@fontsource/orbitron";
import "../../layout.css";

import theme from "../../theme";
import { Helmet } from "react-helmet";
import favicon from "../../static/favicon.ico";
import faviconImg from "../../static/favicon-152.png";
import * as React from "react";
import { PropsWithChildren } from "react";
import styled, { ThemeProvider } from "styled-components";
import { GameContextProvider, STAGING } from "../../contexts/GameContext";
import { ModalDialogProvider } from "../../contexts/ModalDialog";
import { useWindowHeight } from "../../hooks/hooks";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";
import NavBar from "../NavBar";
import Footer from "../Footer";

type PageProps = {
    title?: string;
};

const Main = styled.div<{ height: number }>`
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    overflow-x: hidden;
    min-height: ${(props) => props.height}px;
    background-color: ${(props) => props.theme.bgMain};

    @media (max-width: ${(props) => props.theme.mediaS}) {
        margin-top: 64px;
    }
`;

function getLibrary(provider: any): Web3Provider {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
}

export const PageLabel = styled.div`
    background: rgba(237, 66, 218, 0.1);
    border: 1px solid rgba(237, 66, 218, 0.12);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0 auto;
    width: fit-content;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    @media (max-width: ${(props) => props.theme.mediaS}) {
        font-size: 14px;
        line-height: 22px;
    }
`;

export const PageLabelIcons = styled.div`
    // background: rgba(237, 66, 218, 0.1);
    // border: 1px solid rgba(237, 66, 218, 0.12);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 10px 50px 10px;
    // margin: 0 auto 70px auto;
    width: 100% //fit-content;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    @media (max-width: ${(props) => props.theme.mediaS}) {
        font-size: 14px;
        line-height: 22px;
    }
`;

export function Page({
    title = "Collectible NFT Art and Trading HeroCard Game",
    children,
}: PropsWithChildren<PageProps>): React.ReactElement {
    const height = useWindowHeight();

    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>Satoshi's Quest – {title}</title>
                {STAGING && <meta name="robots" content="noindex" />}
                <link rel="shortcut icon" type="image/x-icon" href={favicon} />
                <link rel="icon" type="image/png" href={faviconImg} />
                <link rel="apple-touch-icon" href={faviconImg} />
            </Helmet>
            <Web3ReactProvider getLibrary={getLibrary}>
                <GameContextProvider>
                    <ModalDialogProvider>
                        <Main height={height}>
                            <NavBar />
                            {children}
                        </Main>
                        <Footer />
                    </ModalDialogProvider>
                </GameContextProvider>
            </Web3ReactProvider>
        </ThemeProvider>
    );
}
