import * as React from "react";
import { CSSProperties } from "react";
import styled from "styled-components";
import { Loading } from "./Loading";
import imgButton from "../../static/button.svg";
import imgButtonSecondary from "../../static/button-secondary.svg";
import imgButtonActive from "../../static/button-active.svg";
import imgButtonWhiteActive from "../../static/button-white-active.svg";
import imgButtonWhiteHover from "../../static/button-white-hover.svg";
import imgButtonWhite from "../../static/button-white.svg";
import imgStripes from "../../static/stripes.svg";

type ButtonProps = {
    label?: string;
    disabled?: boolean;
    isLoading?: boolean;
    secondary?: boolean;
    icon?: string;
    variant?: "light" | "zoom" | "white" | "purple" | "fillPurple";
    size?: "l" | "m" | "s" | "x";
    step?: number;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
};

function clipProgress(step: number): string {
    switch (step) {
        case 3:
            return "clip-path: polygon(0.73% 36.16%, 0.27% 61.12%, 9.29% 96.96%, 65.58% 95.92%, 72.26% 65.91%, 72.26% 42.92%, 65.58% 2px, 9.37% 3.71%);";
        case 2:
            return "clip-path: polygon(0.73% 36.16%, 0.27% 61.12%, 9.29% 96.96%, 43% 95.92%, 50% 63.87%, 50% 42.92%, 43% 2px, 9.37% 3.71%);";
        case 1:
            return "clip-path: polygon(0.73% 36.16%, 0.27% 61.12%, 9.29% 96.96%, 27% 95.92%, 35.84% 63.87%, 35.36% 38.84%, 27% 2px, 9.37% 3.71%);";
        default:
        case 4:
            return "clip-path: polygon(0.73% 36.16%, 0.27% 61.12%, 9.29% 96.96%, 89% 95.92%, 100% 55.71%, 100% 44.96%, 89% 2px, 9.37% 3.71%);";
    }
}

const Container = styled.button<
    Pick<ButtonProps, "size" | "secondary" | "variant" | "step">
>`
    position: relative;
    height: ${(p) => (p.size === "l" ? 68 : p.size === "s" ? 34 : 49)}px;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: ${(p) => (p.variant === "light" ? "normal" : 600)};
    font-family: "Raleway", sans-serif;
    font-size: ${(p) => (p.size === "l" ? 20 : p.size === "s" ? 12 : 15)}px;
    text-transform: ${(p) => (p.variant === "light" ? "normal" : "uppercase")};
    color: ${(p) =>
        p.variant === "light" ? p.theme.colorSecondary : p.theme.colorPrimary};
    border: 0;
    background: none;
    outline: none;
    padding: 0 ${(p) => (p.size === "s" ? 20 : 30)}px;
    border-image-slice: 29 27 29 33 fill;
    border-image-width: ${(p) =>
        p.size === "l" ? "29px 29px 29px 29px" : "20px 20px 20px 20px"};
    border-image-repeat: stretch stretch;
    border-image-source: url("${(p) =>
        p.variant === "light" || p.secondary
            ? imgButtonSecondary
            : imgButton}");
    user-select: none;

    ${(p) =>
        p.step === 4 &&
        `
    border-image-source: url("${imgButtonActive}");
  `};

    & > span {
        z-index: 1;
    }

    &:disabled {
        & > span {
            color: rgba(255, 255, 255, 0.5);
        }
        cursor: initial;
        opacity: 0.5;
    }

    ${(p) =>
        p.step === undefined &&
        `
   &:not(:disabled):hover {
    border-image-source: url("${
        p.variant === "light" ? imgButton : imgButtonActive
    }");
  }`}

    &:before {
        position: absolute;
        display: ${(p) => (p.step ? "block" : "none")};
        content: "";
        width: 100%;
        height: 100%;
        background-image: url(${imgStripes});
        background-position-x: center;
        ${(p) => p.step && clipProgress(p.step)};
    }

    ${(p) =>
        p.variant === "zoom" &&
        `
        border: none;
        padding: 0;
        margin: 0;
        height: auto;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.12);
        backdrop-filter: blur(14px);
        width: 40px;
        min-height: 40px;
        height: 40px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.9s;

        &:not(:disabled):hover {
            border-image-source: none;
            transform: scale(1.6)
        }

        &:not(:disabled):active {
            background: rgba(237, 66, 218, 0.7);
            border: 1px solid rgba(237, 66, 218, 0.8);
        }

        @media (max-width: ${p.theme.mediaS}) {
            width: 30px;
            min-height: 30px;
            height: 30px;
        }
    `};
    ${(p) =>
        p.variant === "white" &&
        `
            border-image-source: url("${imgButtonWhite}");
            color: #fff;
            &:not(:disabled):hover {
                border-image-source: url("${imgButtonWhiteHover}");
            }
            &:not(:disabled):active,
            &:not(:disabled):focus {
                border-image-source: url("${imgButtonWhiteActive}");
            }
    `};

    ${(p) =>
        p.variant === "purple" &&
        `
            color: ${p.theme.colorSecondary};

            &:not(:disabled):hover {
                color: #fff;
            }
    `};
    ${(p) =>
        p.variant === "fillPurple" &&
        `
            border-image-source: url("${imgButtonActive}");
            color: #fff;
            text-transform: none;
            padding: 0 35px;
            border-image-slice: 33 30 32 36 fill;
            height: 54px;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
    `};
`;

const Icon = styled.div<Pick<ButtonProps, "variant">>`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    ${(p) =>
        p.variant === "zoom" &&
        `
       width: 18.5px;
       height: 18.5px;
       margin-right: 0;

        @media (max-width: ${p.theme.mediaS}) {
            width: 13.5px;
            height: 13.5px;
        }
  `};
`;

const Button = ({
    secondary = false,
    isLoading,
    disabled,
    step,
    size,
    label,
    icon,
    variant,
    className,
    style,
    onClick,
}: ButtonProps): JSX.Element => {
    return (
        <Container
            className={className}
            disabled={
                disabled ||
                isLoading === true ||
                (step !== undefined && step !== 4)
            }
            secondary={secondary}
            variant={variant}
            step={step}
            size={size || "l"}
            style={style}
            onClick={onClick}
        >
            <Loading state={isLoading}>
                {icon && (
                    <Icon
                        variant={variant}
                        style={{ backgroundImage: `url(${icon})` }}
                    />
                )}
                <span>{label}</span>
            </Loading>
        </Container>
    );
};

export default Button;
