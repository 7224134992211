import * as React from "react";
import { DialogTitle } from "./CommonDialogElements";
import styled from "styled-components";
import imgWalletCoinbase from "../../static/icons/wallet-coinbase.svg";
import imgWalletMetamask from "../../static/icons/wallet-metamask.svg";
import imgWalletWConnect from "../../static/icons/wallet-wconnect.svg";
import imgConnectionBg from "../../static/connection-bg.svg";
import imgConnectionBgS from "../../static/connection-bg-s.svg";
import { ConnectorNames } from "../../constants/contractAddresses";

const Container = styled.div`
    width: 100%;
    max-width: 400px;
    padding: 0 30px 30px 30px;
    box-sizing: border-box;
`;

const Connection = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 348px;
    height: 56px;
    background: url(${imgConnectionBg}) center center no-repeat;
    background-size: contain;

    &:after {
        position: absolute;
        right: 45px;
        top: 18px;
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        background: url(${imgWalletCoinbase}) center center no-repeat;
        background-size: contain;
    }

    &[data-name="${ConnectorNames.Injected}"]:after {
        background-image: url(${imgWalletMetamask});
    }

    &[data-name="${ConnectorNames.WalletConnect}"]:after {
        background-image: url(${imgWalletWConnect});
    }

    @media (max-width: 460px) {
        margin: 0 auto;
        width: 156px;
        height: 228px;
        //TODO: fix mobile connection image
        background-image: url(${imgConnectionBgS});

        &:after {
            top: initial;
            left: 62px;
            bottom: 18px;
        }
    }
`;

export function ConnectionDialog({
    connectionMode,
}: {
    connectionMode?: ConnectorNames;
}): JSX.Element {
    return (
        <Container>
            <DialogTitle data-type={"center"}>Connection</DialogTitle>
            {connectionMode && <Connection data-name={connectionMode} />}
        </Container>
    );
}
