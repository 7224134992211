import { ethers } from "ethers";

export interface ProofMapping {
    // Token id : ...
    [key: string]: { ipfsUri: string; merkleProof: Array<string>; dna: number };
}

export interface WhitelistProofMapping {
    // user address : ..
    [key: string]: {
        cap: number;
        merkleProof: Array<string>;
        partner: boolean;
    };
}

// Key: chainId, Value: deployed address
export const SELLING_CONTROLLER_ADDRESSES: { [key: number]: string } = {
    4: "0x9E798B67bC562790110eB50dd40Ba6E0A1Ff8a7C",
    31337: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
    1: "0xA524FEee0Bb8e1FE759e281422e153DcD784564d",
};
// 59144: "0x15f0bf5ff3f5aa1a3ff10120c1da669b80309642",
export const SELLING_RELEASE_DATE_UNIX_TS_SECONDS = 1638640800;

export const GAME_CONTROLLER_ADDRESSES: {
    [key: number]: { address: string };
} = {
    59144: {
        address: "0x15f0bf5ff3f5aa1a3ff10120c1da669b80309642",
    },
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const BACKEND = process.env.BACKEND!;
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DEFAULT_NETWORK = process.env.DEFAULT_NETWORK!;

export const NETWORK_IDS: { [network: string]: number } = {
    linea: 59144,
};

export const CDN_URL_BASE = "https://static.satoshiquest.io/linea"
// : { [network: string]: string } = {
//     [NETWORK_IDS["rinkeby"]]: "https://static.satoshiquest.io/staging",
//     [NETWORK_IDS["mainnet"]]: "https://static.satoshiquest.io/production",
//     [NETWORK_IDS["hardhat"]]: "https://static.satoshiquest.io/linea",
// };
// [NETWORK_IDS["linea"]]: "https://static.satoshiquest.io/staging",

export const DEFAULT_CHAIN_ID = NETWORK_IDS[DEFAULT_NETWORK];
export const DefaultRPCNode = `wss://${DEFAULT_NETWORK}.infura.io/ws/v3/8fcf8c7887bc42b3838861a96e1b6a65`; //`wss://linea-goerli.infura.io/v3/62c924b524d6455dba70cd9fa31219ba`;
// const DefaultRPCNode = `ws://127.0.0.1:8545`;
// NOTE: Gatsby cannot create a websocket connection at build time, so this is
// a runtime - only WSprovider constructor
export const DEFAULT_PROVIDER = (): ethers.providers.WebSocketProvider =>
    new ethers.providers.WebSocketProvider(DefaultRPCNode);

export enum ConnectorNames {
    Injected = "metamask",
    WalletConnect = "wconnect",
}

/**
 * ************************ WALLET SPECIFIC DATA ************************
 */
// ------------ Injected ---------------- //
export const SUPPORTED_CHAINS = [31337, 4, 1];

// ------------ WalletConnect ---------------- //
export const RPC_URLS: { [chainId: number]: string } = {
    // NOTE: WalletConnect does not support local nodes. Only testnets + mainnet.
    // 4: `https://rinkeby.infura.io/v3/8fcf8c7887bc42b3838861a96e1b6a65`,
    // 1: `https://mainnet.infura.io/v3/8fcf8c7887bc42b3838861a96e1b6a65`,
    // 31337: "https://linea-goerli.infura.io/v3/62c924b524d6455dba70cd9fa31219ba",
    59144: "https://linea.infura.io/v3/62c924b524d6455dba70cd9fa31219ba",
};
// 59144: "https://linea.infura.io/v3/62c924b524d6455dba70cd9fa31219ba",