import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import pdf from "../../static/NFT_PURCHASE_TERMS_&_CONDITIONS_FOR_NFT_PURCHASE.pdf";
import { DialogTitle } from "./CommonDialogElements";
import imgButtonConnection from "../../static/button-connection.svg";
import imgButtonConnectionA from "../../static/button-connection-a.svg";
import imgWalletMetamask from "../../static/icons/wallet-metamask.svg";
import imgWalletWConnect from "../../static/icons/wallet-wconnect.svg";
import { ConnectionDialog } from "./ConnectionDialog";
import MetamaskOnboarding from "@metamask/onboarding";
import { windowHasMetamask } from "../../utils";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ConnectorNames } from "../../constants/contractAddresses";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectDialog } from "./WalletConnectDialog";
import { useConnect } from "../../hooks/hooks";

const Container = styled.div`
    max-width: 400px;
    padding: 0 30px 30px 30px;
    box-sizing: border-box;

    @media (max-width: ${(props) => props.theme.mediaS}) {
        padding: 0;
    }

    & p {
        margin: 20px 0 0 0;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #ffffff;
    }
`;

interface ConnectionProps {
    disabled?: boolean;
}

const ConnectionButton = styled.div<ConnectionProps>`
    position: relative;
    width: auto;
    height: 50px;
    padding: 0 20px;
    margin-bottom: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 148%;
    letter-spacing: 0.02em;
    color: #05fffa;
    border-image-slice: 22 22 22 22 fill;
    border-image-width: 20px 20px 20px 20px;
    border-image-repeat: stretch stretch;
    border-image-source: url(${imgButtonConnection});
    cursor: ${(p) => (p.disabled ? "" : "pointer")};
    filter: ${(p) => (p.disabled ? "grayscale(1)" : "grayscale(0)")};

    &:hover {
        border-image-source: url(${(p) =>
            p.disabled ? imgButtonConnection : imgButtonConnectionA});
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:after {
        display: block;
        position: absolute;
        right: 20px;
        width: 24px;
        height: 24px;
        content: "";
        background-size: contain;
        background-position: center right;
        background-repeat: no-repeat;
    }

    &[data-name="${ConnectorNames.Injected}"]:after {
        background-image: url(${imgWalletMetamask});
    }

    &[data-name="${ConnectorNames.WalletConnect}"]:after {
        background-image: url(${imgWalletWConnect});
    }
`;
const RPC_URLS = {
    59144: "https://linea-mainnet.infura.io/v3/9d82a62594394adcabfb50eaa8e70fad",
};
const INFURA_ID = "8fcf8c7887bc42b3838861a96e1b6a65";
const SUPPORTED_CHAINS = [59144];
export const injected = new InjectedConnector({
    supportedChainIds: SUPPORTED_CHAINS,
});

export function ConnectDialog(): React.ReactElement {
    const connect = useConnect();
    const [advance, setAdvance] = useState<number | undefined>(undefined);
    const [walletType, setWalletType] = useState<ConnectorNames | undefined>(
        undefined
    );
    const { activate, active, error } = useWeb3React<Web3Provider>();
    const [qrString, setQrString] = useState<string>("");

    const walletconnect = React.useMemo(
        () =>
            new WalletConnectConnector({
                rpc: RPC_URLS,
                qrcode: true,
                // pollingInterval: 12000,
                infuraId: INFURA_ID,
                // qrcodeModal: {
                //     open: (uri: any, cb: any) => {
                //         setQrString(uri);
                //     },
                //     close: () => {},
                // },
            }),
        []
    );
    const connectorsByName: { [connectorName in ConnectorNames]: any } =
        React.useMemo(
            () => ({
                [ConnectorNames.Injected]: injected,
                [ConnectorNames.WalletConnect]: walletconnect,
            }),
            [walletconnect]
        );

    const WalletDialogAdvancement: {
        [connectorName in ConnectorNames]: JSX.Element[];
    } = React.useMemo(
        () => ({
            [ConnectorNames.Injected]: [
                <ConnectionDialog connectionMode={walletType} />,
            ],
            [ConnectorNames.WalletConnect]: [
                <ConnectionDialog connectionMode={walletType} />,
                <WalletConnectDialog qrCode={qrString} />,
            ],
        }),
        [qrString, walletType]
    );
    React.useEffect(() => {
        if (active) {
            setAdvance(0);
        }
    }, [active, advance]);

    const isMetamask = React.useMemo(() => windowHasMetamask(window), [window]);

    if (advance !== undefined && walletType !== undefined) {
        return WalletDialogAdvancement[walletType][advance];
    }

    const handleMetaMaskNetworkSwitch = async () => {
        const currentChainId = await (window as any).ethereum.request({
            method: "eth_chainId",
        });
        if (currentChainId !== "0xE708") {
            try {
                await (window as any).ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: "0xE708" }],
                });
            } catch (switchError: any) {
                if (switchError.code === 4902) {
                    try {
                        await (window as any).ethereum.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: "0xE708",
                                    chainName: "Linea Mainnet",
                                    rpcUrls: [
                                        "https://linea-mainnet.infura.io/v3/",
                                    ],
                                    nativeCurrency: {
                                        name: "ETH",
                                        symbol: "ETH",
                                        decimals: 18,
                                    },
                                    blockExplorerUrls: [
                                        "https://lineascan.build",
                                    ],
                                },
                            ],
                        });
                    } catch (addError) {
                        console.log(addError);
                        return;
                    }
                } else {
                    console.log(switchError);
                    return;
                }
            }
        }
    };

    return (
        <Container>
            <DialogTitle>Choose a connection</DialogTitle>
            <ConnectionButton
                disabled={!isMetamask}
                data-name={"metamask"}
                onClick={async () => {
                    if (isMetamask) {
                        setWalletType(ConnectorNames.Injected);
                        const connector =
                            connectorsByName[ConnectorNames.Injected];
                        await connect(connector, ConnectorNames.Injected);
                        await handleMetaMaskNetworkSwitch();
                    } else if (!isMetamask) {
                        new MetamaskOnboarding().startOnboarding();
                    }
                }}
            >
                MetaMask
            </ConnectionButton>
            <ConnectionButton
                data-name={"wconnect"}
                onClick={async () => {
                    setWalletType(ConnectorNames.WalletConnect);
                    setAdvance(1);
                    const connector =
                        connectorsByName[ConnectorNames.WalletConnect];
                    await connect(connector, ConnectorNames.WalletConnect);
                }}
            >
                WalletConnect
            </ConnectionButton>
            <p>
                By connecting a wallet, you agree to{" "}
                <a href={pdf} target="_blank">
                    Terms of Service
                </a>{" "}
                and acknowledge that you have read and understand the product
                disclaimer.
            </p>
        </Container>
    );
}
