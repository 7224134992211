import * as React from "react";
import { useCallback } from "react";
import { navigate } from "gatsby";

import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import styled from "styled-components";
import imgWalletBorder from "../static/icons/wallet-border.svg";
import imgIconWallet from "../static/icons/icon-wallet.svg";
import imgIconWalletActive from "../static/icons/icon-wallet-active.svg";
import Button from "./common/Button";
import { useModalDialog } from "../contexts/ModalDialog";
import { ConnectDialog } from "./dialogs/ConnectDialog";
import { FixedNumber } from "ethers";
import { useBalance } from "../hooks/hooks";
import useMedia from "use-media";
import theme from "../theme";
import { PRE_RELEASE } from "../contexts/GameContext";

const WalletInfo = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on;

    @media (max-width: ${(props) => props.theme.mediaS}) {
        font-size: 10px;
        font-weight: bold;
    }
`;

const WalletValue = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 143px;
    height: 39px;
    background: url(${imgWalletBorder}) center left no-repeat;

    @media (max-width: ${(props) => props.theme.mediaS}) {
        width: 90px;
        height: 32px;
        background-size: cover;
        padding-right: 10px;
    }
`;

const WalletIcon = styled.div`
    width: 43px;
    height: 39px;
    margin-left: -18px;
    text-align: center;
    background: url(${imgIconWallet}) center no-repeat;
    cursor: pointer;

    &:hover {
        background-image: url(${imgIconWalletActive});
    }

    @media (max-width: ${(props) => props.theme.mediaS}) {
        width: 38px;
        height: 32px;
        background-size: contain;
    }
`;

const WalletButton = (): JSX.Element => {
    const { account, active } = useWeb3React<Web3Provider>();
    const balance = useBalance();
    const dialog = useModalDialog();
    const isS = useMedia({ maxWidth: theme.mediaS });

    const handleConnect = useCallback(() => {
        dialog({
            children: ConnectDialog,
        })
            .then(() => {
                console.log("ok");
            })
            .catch(() => {
                console.log("close");
            });
    }, []);

    const goTo = (path: string) => () => navigate(path);

    return (
        <>
            {active && balance && account ? (
                <WalletInfo>
                    <WalletValue>
                        {FixedNumber.fromValue(balance, 18).round(4).toString()}{" "}
                        ETH
                    </WalletValue>
                    <WalletIcon onClick={goTo("/wallet")} />
                </WalletInfo>
            ) : (
                <Button
                    disabled={PRE_RELEASE}
                    label={"Connect"}
                    size={isS ? "s" : "m"}
                    style={!isS ? { minWidth: 120, width: 137 } : undefined}
                    onClick={handleConnect}
                />
            )}
        </>
    );
};

export default WalletButton;
