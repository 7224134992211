import imgFile from "../static/clues/file.png";
import imgAddress from "../static/clues/address.png";
import imgBitcoin from "../static/clues/bitcoin.png";
import imgBitcoinPizza from "../static/clues/bitcoin-pizza.png";
import imgCylinder from "../static/clues/cylinder.png";
import imgCamel from "../static/clues/camel.png";
import imgVitalik from "../static/clues/vitalik.png";
import imgTrezor from "../static/clues/trezor.png";
import imgEther from "../static/clues/ether.png";
import imgMuskCoin from "../static/clues/muskCoin.png";
import imgLedger from "../static/clues/ledger.png";
import imgDoge from "../static/clues/doge.png";
import imgMuskStatue from "../static/clues/muskStatue.png";
import imgLightning from "../static/clues/lightning.png";
import imgSkull from "../static/clues/skull.png";

type Clue = {
    title: string;
    desc: string;
    img: string;
};

export const clueList: Clue[] = [
    {
        title: "Bitcoin whitepaper",
        desc: "Released on Halloween, 31st October 2008, the Bitcoin whitepaper uses many British English spellings, as do Satoshi’s forum posts - Could Satoshi be from the UK?",
        img: imgFile,
    },
    {
        title: "First bitcoin transaction",
        desc: "On 12th January 2009, Satoshi sent the first bitcoin transaction, to a developer named Hal Finney - Could there be a clue in Finney’s correspondence with Satoshi?",
        img: imgAddress,
    },
    {
        title: "Bitcointalk forums",
        desc: "In November 2009 the Bitcointalk forum launched. An analysis of Satoshi’s forum posts reveals a drop in activity from 5am to 11am UTC - But was Satoshi a night owl or a morning person?",
        img: imgBitcoin,
    },
    {
        title: "Bitcoin pizza",
        desc: "On 22nd May 2010 Bitcoin developer Laszlo Hanyecz used 10,000 BTC to purchase 2 Papa Johns pizzas, inadvertently founding Bitcoin Pizza Day - Hanyecz claimed that communication with Satoshi was “mostly weird” and never contained any personal information.",
        img: imgBitcoinPizza,
    },
    {
        title: "184 billion BTC bug",
        desc: "Block 74638 saw a bug exploited to create 184 billion BTC. It was quickly patched, but why was the number of bitcoin capped at 21 million anyway? - Does the number 21 have special meaning to Satoshi?",
        img: imgCylinder,
    },
    {
        title: "Silk Road launch",
        desc: "Satoshi didn’t resurface to comment when the Silk Road dark-web marketplace was launched - Would Satoshi have approved of its libertarian ideals, or despaired that Bitcoin was used for such purposes?",
        img: imgCamel,
    },
    {
        title: "Bitcoin Magazine",
        desc: "The first issue of Bitcoin Magazine was published in May 2012 with Vitalik Buterin at the helm - Did Buterin know who Satoshi was?",
        img: imgVitalik,
    },
    {
        title: "Trezor crowdfunding",
        desc: "Trezor held a crowdfunding event for its first Bitcoin hardware wallet in June 2013 - Could Satoshi have been an early supporter, looking for somewhere to store all that mined BTC?",
        img: imgTrezor,
    },
    {
        title: "Ethereum launch",
        desc: "Despite his persistently malnourished appearance, Vitalik Buterin has lived to a ripe old age - Perhaps you could track him down in person to see what he knows?",
        img: imgEther,
    },
    {
        title: "Craig Wright",
        desc: "In May 2016 Craig Wright announced that he was Satoshi Nakamoto, although following that consistently failed to provide any conclusive proof - Faketoshi is surely just a hoaxer, right?",
        img: imgMuskCoin,
    },
    {
        title: "Craig Wrong",
        desc: "In May 2016 Craig Wright announced that he was Satoshi Nakamoto, although following that consistently failed to provide any conclusive proof - Faketoshi is surely just a hoaxer, right?",
        img: imgMuskCoin,
    },
    {
        title: "Bitcoin Cash fork",
        desc: "In August 2017 a major fork saw the creation of Bitcoin Cash, led by Roger Ver, who believed Bitcoin should return to its original perceived purpose as digital cash - Did Ver know more about Satoshi’s vision than he admitted?",
        img: imgLedger,
    },
    {
        title: "Dogecoin CEO",
        desc: "On 1st April 2019, Elon Musk was briefly named Dogecoin CEO after tweeting his support for the meme cryptocurrency - Was this just an April Fools, or has Musk been trolling us all along?",
        img: imgDoge,
    },
    {
        title: "Tesla BTC purchase",
        desc: "In February 2021 Tesla announced that it had bought $1.5 billion worth of BTC. Could PayPal co-founder Musk have been involved in Bitcoin’s creation? - Perhaps he left a clue?",
        img: imgMuskStatue,
    },
    {
        title: "Lightning Network launch",
        desc: "On 19th January 2024, five years to the day after the launch of the inaugural Lightning torch, Bitcoin’s layer-2 Lightning Network was finally reclassified as ‘not an experiment’ - How did Satoshi feel about this addition to the Bitcoin network?",
        img: imgLightning,
    },
    {
        title: "Hash Mask",
        desc: "As Bitcoin reached the ripe old age of ten, non-fungible tokens started to gain popularity, representing digital art and collectibles. Did Satoshi see this as a corruption of blockchain’s initial purpose, or take pride in the technology being used for other purposes?",
        img: imgSkull,
    },
];
