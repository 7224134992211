import "prismjs/themes/prism.css";

import React from "react";
import {Page} from "./src/components/common/Page";

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (!(`IntersectionObserver` in window)) {
        import(`intersection-observer`);
        console.log(`# IntersectionObserver is polyfilled!`);
    }
};

export const wrapPageElement = ({element, props}) => (
    <Page {...props}>{element}</Page>
);
