export default {
    gbMain: "#1E1E1E",
    gbDialog: "#1E1E1E",
    colorPrimary: "#05FFFA",
    colorSecondary: "#ED42DA",
    colorYellow: "#FFD572",
    colorPurple: "#AF73FF",
    colorText: "#FFFFF",
    colorBlack: "#1E1E1E",
    colorError: "#FF002D",
    colorGrey: "#485A66",

    mediaL: "1000px",
    mediaM: "800px",
    mediaS: "450px",
};
