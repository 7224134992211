import * as React from "react";
import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import imgDialogClose from "../static/dialog-close.svg";
import imgDialogCloseWhite from "../static/dialog-close-white.svg";
import imgDialogClosePurpleSecond from "../static/icons/icon-close-modal-2.svg";
import iconOpenSea from "../static/icons/Group.png";
import Button from "../components/common/Button";

type CloseColor = "purple" | "white" | "purpleStrict";
type ClosePosition = "right" | "top";

export type ModalDialogOptions = {
    frame?: boolean;
    backdrop?: string;
    background?: string;
    backgroundColor?: string;
    shadows?: string;
    closeColor?: CloseColor;
    closePosition?: ClosePosition;
    openSeaUrl?: string;
    backgroundMobile?: string;
    border?: string;
    fullInMobile?: boolean;
    children?: React.FC<ModalDialogProps>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ModalDialogProps<T = {}> = ModalDialogOptions & {
    open: boolean;
    onSubmit(): void;
    onClose(): void;
} & T;

const DialogWrapper = styled.div<{ backdrop?: string }>`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;

    ${(props) =>
        props.backdrop &&
        `
        background: rgba(0, 0, 0, 0.6) url(${props.backdrop}) center no-repeat;
        background-size: cover;
    `}
`;

const Dialog = styled.div<{
    background?: string;
    border?: string;
    backgroundMobile?: string;
    backgroundColor?: string;
    shadows?: string;
    fullInMobile?: boolean;
}>`
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 800px;
    overflow: hidden;
    height: auto;
    margin: 10px;
    box-sizing: border-box;
    background: ${(props) => props.theme.gbDialog};
    border-radius: 5px;

    ${(props) =>
        props.background &&
        `
        background: url(${props.background}) center no-repeat, ${
            props.backgroundColor
                ? props.backgroundColor
                : "rgba(30, 30, 30, 0.2)"
        };
        box-sizing: border-box;
        backdrop-filter: blur(75px);
    `}
    ${(props) =>
        props.border &&
        `
        border: 2px solid ${props.border};
    `}
    ${(props) =>
        props.shadows &&
        `
        box-shadow: ${props.shadows};
    `}
    ${(props) =>
        props.backgroundMobile &&
        `
        @media (max-width: ${props.theme.mediaS}) {
            background: ${
                props.backgroundColor
                    ? props.backgroundColor
                    : "rgba(30, 30, 30, 0.2)"
            } url(${props.backgroundMobile}) center no-repeat;
        }
    `}
    ${(props) =>
        props.fullInMobile &&
        `
        @media (max-width: ${props.theme.mediaS}) {
            margin: 0;
            height: 100%;
        }
    `}
`;

const DialogHeader = styled.div<{
    closePosition: ClosePosition;
    fullInMobile?: boolean;
}>`
    padding: 20px 20px 0 20px;
    align-self: flex-end;

    ${(props) =>
        props.closePosition === "top" &&
        `
        margin-right: 20px;
        margin-bottom: 40px;
    `}

    ${(props) =>
        props.fullInMobile &&
        `
        @media (max-width: ${props.theme.mediaS}) {
            display: none;
        }
    `}
`;

const DialogCloseButton = styled.div<{ color: CloseColor }>`
    width: 15px;
    height: 15px;
    background: url(${(props) =>
            props.color === "white" ? imgDialogCloseWhite : imgDialogClose})
        center no-repeat;
    background-size: contain;
    cursor: pointer;

    ${(props) =>
        props.color === "purpleStrict" &&
        `
        background: url(${imgDialogClosePurpleSecond});
        width: 20px;
        height: 20px;
    `}
`;

const DialogContent = styled.div<{ fullInMobile?: boolean }>`
    display: flex;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;

    ${(props) =>
        props.fullInMobile &&
        `
        @media (max-width: ${props.theme.mediaS}) {
            padding: 0;
            height: 100%;
        }
    `}
`;

const OpenSeaBtn = styled(Button)`
    margin: 20px auto 0 auto;

    @media (max-width: ${(props) => props.theme.mediaS}) {
        display: none;
    }
`;

const ModalDialogContext = createContext<
    (options: ModalDialogOptions) => Promise<void>
>(Promise.reject.bind(Promise));

export function ModalDialog(props: ModalDialogProps): JSX.Element | null {
    const {
        open,
        frame = true,
        children,
        onClose,
        backdrop,
        background,
        border,
        backgroundColor,
        backgroundMobile,
        shadows,
        closeColor = "purple",
        closePosition = "right",
        openSeaUrl,
        fullInMobile,
    } = props;
    if (!open) return null;

    const onBackdropClick = (event: any) => {
        if (!document.getElementById("modal")?.contains(event.target)) {
            onClose();
        }
    };

    return (
        <DialogWrapper
            id="modal-backdrop"
            onClick={(event) => onBackdropClick(event)}
            backdrop={backdrop}
        >
            <Dialog
                id="modal"
                backgroundColor={backgroundColor}
                shadows={shadows}
                fullInMobile={fullInMobile}
                backgroundMobile={backgroundMobile}
                border={border}
                background={background}
                style={!frame ? { background: "none" } : {}}
            >
                <DialogHeader
                    fullInMobile={fullInMobile}
                    closePosition={closePosition}
                    style={!frame ? { padding: 0 } : {}}
                >
                    <DialogCloseButton
                        color={closeColor}
                        onClick={() => onClose()}
                    />
                </DialogHeader>
                <DialogContent fullInMobile={fullInMobile}>
                    {children && children(props)}
                </DialogContent>
                {openSeaUrl && (
                    <OpenSeaBtn
                        size={"m"}
                        label={"View on Element"}
                        variant="fillPurple"
                        icon={iconOpenSea}
                        onClick={() => {
                            window.open(openSeaUrl, "_blank");
                        }}
                    />
                )}
            </Dialog>
        </DialogWrapper>
    );
}

export const ModalDialogProvider = ({
    children,
    backdrop,
    background,
    border,
    backgroundMobile,
    backgroundColor,
    shadows,
    closeColor,
    closePosition,
    openSeaUrl,
    fullInMobile,
}: {
    children?: ReactNode;
    backdrop?: string;
    background?: string;
    border?: string;
    backgroundMobile?: string;
    backgroundColor?: string;
    shadows?: string;
    closeColor?: CloseColor;
    closePosition?: ClosePosition;
    openSeaUrl?: string;
    fullInMobile?: boolean;
}): ReactNode => {
    const [state, setState] = useState<ModalDialogOptions | null>(null);

    const promiseRef = useRef<{
        resolve(): void;
        reject(): void;
    }>();

    const openModal = (options: ModalDialogOptions) => {
        setState(options);
        return new Promise<void>((resolve, reject) => {
            promiseRef.current = { resolve, reject };
        });
    };

    const handleClose = () => {
        if (promiseRef.current) {
            promiseRef.current.reject();
        }
        setState(null);
    };

    const handleSubmit = () => {
        if (promiseRef.current) {
            promiseRef.current.resolve();
        }
        setState(null);
    };

    useEffect(() => {
        document.body.style.setProperty("overflow", state ? "hidden" : "unset");
    }, [state]);

    return (
        <>
            <ModalDialogContext.Provider value={openModal}>
                {children}
            </ModalDialogContext.Provider>
            <ModalDialog
                closeColor={closeColor}
                backgroundColor={backgroundColor}
                shadows={shadows}
                border={border}
                background={background}
                backdrop={backdrop}
                backgroundMobile={backgroundMobile}
                open={Boolean(state)}
                onSubmit={handleSubmit}
                onClose={handleClose}
                openSeaUrl={openSeaUrl}
                closePosition={closePosition}
                fullInMobile={fullInMobile}
                {...state}
            />
        </>
    );
};

type UseModalReturn = (options: ModalDialogOptions) => Promise<void>;
export const useModalDialog = (): UseModalReturn =>
    useContext(ModalDialogContext);
