import * as React from "react";
import styled from "styled-components";
import QRCode from "react-qr-code";
import { DialogTitle } from "./CommonDialogElements";
import imgIconWConnect from "../../static/icons/wallet-wconnect.svg";
import { Loading } from "../common/Loading";

const Container = styled.div`
    max-width: 350px;
    margin: 0 30px 30px 30px;
    box-sizing: border-box;

    & > div:first-child:before {
        width: 24px;
        height: 15px;
        content: "";
        display: inline-block;
        margin-right: 15px;
        background: url(${imgIconWConnect});
    }

    & p {
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
    }

    @media (max-width: ${(props) => props.theme.mediaS}) {
        margin: 0;
    }
`;

const QRCodeWrapper = styled.div`
    display: flex;
    background: #fff;
    padding: 40px;
    border-radius: 5px;
    width: fit-content;
    min-width: 200px;

    @media (max-width: ${(props) => props.theme.mediaM}) {
        padding: 20px;
    }
`;

const CopyToClipboard = styled.div`
    display: inline-block;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    user-select: none;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        text-decoration: underline;
    }
`;

interface Props {
    qrCode?: string;
}

export function WalletConnectDialog({ qrCode }: Props): React.ReactElement {
    const handleCopy = async () => {
        await navigator.clipboard.writeText(qrCode || "");
        alert("Address has been copied!");
    };

    return (
        <Container>
            <DialogTitle>WalletConnect</DialogTitle>
            <p>Scan GR code with a WalletConnect-compatible wallet</p>
            <Loading state={!qrCode}>
                <QRCodeWrapper>
                    <QRCode value={qrCode || ""} size={220} />
                </QRCodeWrapper>
            </Loading>
            <CopyToClipboard onClick={handleCopy}>
                Copy to clipboard
            </CopyToClipboard>
        </Container>
    );
}
