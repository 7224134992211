import * as React from "react";
import styled from "styled-components";
import { DialogTitle } from "./CommonDialogElements";
import Button from "../common/Button";
import { ModalDialogProps } from "../../contexts/ModalDialog";

const Container = styled.div`
    width: 420px;
`;

const Box = styled.div`
    display: flex;
    margin: 20px;
    justify-content: center;

    & > button:first-child {
        margin-right: 20px;
    }
`;

export function ConfirmationDialog({
    onSubmit,
    onClose,
}: ModalDialogProps): JSX.Element {
    return (
        <Container>
            <DialogTitle data-type={"center"}>Are you sure?</DialogTitle>
            <Box>
                <Button label={"Yes"} size={"m"} onClick={onSubmit} />
                <Button
                    secondary
                    label={"Close"}
                    size={"m"}
                    onClick={onClose}
                />
            </Box>
        </Container>
    );
}
