import { Required } from "ts-toolbelt/out/Object/Required";
import MetamaskOnboarding from "@metamask/onboarding";
import axios, { AxiosResponse } from "axios";
import { BACKEND } from "./constants/contractAddresses";
// кор: тут нужна будет корректировка контрактов
import { NFT } from "./contracts/types/NFT";
import { BigNumberish } from "ethers";
import React from "react";
import { useGame } from "./contexts/GameContext";

export const windowHasMetamask = (
    window: Window
): window is Required<Window, "ethereum"> =>
    MetamaskOnboarding.isMetaMaskInstalled();

export function formatNumber(number: number): string {
    return new Intl.NumberFormat("us", {}).format(number);
}

export function scrollToElement(id: string): void {
    const element = document.querySelector(id);
    if (element) {
        window.scrollTo({
            top: element.getBoundingClientRect().top + window.pageYOffset - 130,
            behavior: "smooth",
        });
    }
}

// ----------------------- Requester  ----------------------- //

// Envelope
export type ApiResponse<T> = {
    msg: string;
    data: T;
};

// Queries
export type Filters = {
    owner?: string;
    heroes?: Array<string>;
    locations?: Array<string>;
    levels?: Array<string>;
    clue?: Array<string>;
    clueShard?: Array<string>;
    eyes?: Array<string>;
    mouth?: Array<string>;
    clothes?: Array<string>;
};
export type IncludeHidden = { includeHidden: boolean };
export type Offset = { offset: number };
export type Owner = { owner?: string };
export type DisplayFilter = {
    type: keyof Filters;
    label: string;
    selected?: boolean;
    id: string;
};

// Responses
type BackendCard = {
    dna: number;
    tokenId: string;
    metadata: {
        name: string;
        image: string;
        description: string;
        attributes?: Array<Attribute>;
    };
};

export type UpgradeResponse = {
    signedMessage: string;
    tokenUpgraded: number;
    tokenTwo: number;
    newDna: number;
    newIpfs: string;
};

type GenerateCard = {
    newDna: number;
    newIpfs: string;
};
export type FindSatoshiResponse = {
    signedMessage: string;
    freakCardId: number;
    geekCardId: number;
    slackerCardId: number;
    hackerCardId: number;
    newCards: Array<GenerateCard>;
};

export class Requester {
    static async health(): Promise<AxiosResponse<string>> {
        const alive = await axios.get<string>(`${BACKEND}/health`);
        return alive;
    }
    // --------- Gallery Start  --------- //
    static async getGalleryCards(
        query: Filters & Owner & IncludeHidden,
        offset: number
    ): Promise<AxiosResponse<ApiResponse<Array<BackendCard>>>> {
        const rawCardsProm = await axios.post<ApiResponse<Array<BackendCard>>>(
            `${BACKEND}/gallery`,
            { offset, query }
        );
        return rawCardsProm;
    }

    static async getTotalCardsInGallery(
        query: Filters & Owner & IncludeHidden
    ): Promise<AxiosResponse<ApiResponse<number>>> {
        const rawCardsProm = await axios.post<ApiResponse<number>>(
            `${BACKEND}/gallery/total-cards`,
            query
        );
        return rawCardsProm;
    }
    // ######## Gallery end  ######## //

    // --------- Force refresh start  --------- //
    static async forceRefresh(
        tokenId: number
    ): Promise<AxiosResponse<ApiResponse<null>>> {
        const resp = await axios.get<ApiResponse<null>>(
            `${BACKEND}/force-update-check`,
            { params: { tokenId } }
        );
        return resp;
    }
    // ######## Force refresh end  ######## //
    // --------- Upgrades Start  --------- //
    static async performUpgrade(
        signedMessage: string,
        tokenIdToUpgrade: number,
        tokenIdDonor: number,
        recaptchaToken: string
    ): Promise<AxiosResponse<ApiResponse<UpgradeResponse>>> {
        const resp = await axios.post<ApiResponse<UpgradeResponse>>(
            `${BACKEND}/upgrades/normal`,
            {
                signedMessage,
                tokenIdToUpgrade,
                tokenIdDonor,
                recaptchaToken,
            }
        );
        return resp;
    }
    static async fetchUpgradeMessage(
        tokenIdToUpgrade: number,
        tokenIdDonor: number
    ): Promise<AxiosResponse<ApiResponse<{ message: string }>>> {
        const message = await axios.get<ApiResponse<{ message: string }>>(
            `${BACKEND}/upgrades/normal/message`,
            {
                params: {
                    tokenIdToUpgrade,
                    tokenIdDonor,
                },
            }
        );
        return message;
    }
    // ######### Upgrades end  ######### //
    // --------- Find satoshi Start  --------- //
    static async performFindSatoshi(
        signedMessage: string,
        freakCardId: number,
        geekCardId: number,
        slackerCardId: number,
        hackerCardId: number,
        recaptchaToken: string
    ): Promise<AxiosResponse<ApiResponse<FindSatoshiResponse>>> {
        const resp = await axios.post<ApiResponse<FindSatoshiResponse>>(
            `${BACKEND}/upgrades/grand`,
            {
                signedMessage,
                freakCardId,
                geekCardId,
                slackerCardId,
                hackerCardId,
                recaptchaToken,
            }
        );
        return resp;
    }
    static async fetchFindSatoshiMessage(
        freakCardId: number,
        geekCardId: number,
        slackerCardId: number,
        hackerCardId: number
    ): Promise<AxiosResponse<ApiResponse<{ message: string }>>> {
        const message = await axios.get<ApiResponse<{ message: string }>>(
            `${BACKEND}/upgrades/grand/message`,
            {
                params: {
                    freakCardId,
                    geekCardId,
                    slackerCardId,
                    hackerCardId,
                },
            }
        );
        return message;
    }
    // ######### Find satoshi end  ######### //
}

// ------------ IPFS manipulation ------------ //
export const CARD_CHUNK = 5;

export function ipfsUrlFromHash(h: string): string {
    // High throttle limit - responds with status 429 quite often
    // return `https://ipfs.io/ipfs/${h}`;

    // No throttle rate?
    return `https://${h}.ipfs.dweb.link`;
}

export type Hero = {
    image: string;
    name: string;
    description: string;
    attributes?: Array<Attribute>;
    selected?: boolean;
    dna?: number;
    tokenId: number;
};

export type Attribute = {
    trait_type?: string;
    value: string;
};
export function extractDataFromIpfsUri(uri: string): {
    hash: string;
    path: string;
} {
    const split = uri.split("/");
    return { hash: split[2] || "", path: split[3] || "" };
}

export function getHeroHttpsImage(hero: Hero): string {
    const imageData = extractDataFromIpfsUri(hero.image);
    const image = `${ipfsUrlFromHash(imageData.hash)}/${imageData.path}`;
    return image;
}
export function useHeroHttpsImageCDN(heroImagePathUri: string): {
    cdn: string;
    cdnThumbnail: string;
    ipfs: string;
} {
    const { cachedChainId } = useGame();
    const res = React.useMemo(() => {
        return constructCdnUrl(heroImagePathUri, cachedChainId);
    }, [cachedChainId, heroImagePathUri]);
    return res;
}

const CDN_URL_BASE = "https://static.satoshiquest.io/linea";
function constructCdnUrl(
    path: string,
    chainId: number
): { cdn: string; ipfs: string; cdnThumbnail: string } {
    const imageData = extractDataFromIpfsUri(path);
    const ipfs = `${ipfsUrlFromHash(imageData.hash)}/${imageData.path}.jpeg`;
    const cdn = `${CDN_URL_BASE}/${imageData.hash}.jpeg`;
    const cdnThumbnail = `${CDN_URL_BASE}/thumbnails/${imageData.hash}.jpeg`;
    return {
        cdn,
        ipfs,
        cdnThumbnail,
    };
}

export async function getHeroFromIpfs(
    hash: string,
    path: string
): Promise<Hero> {
    const heroFetched = await axios.get<Hero>(
        `${ipfsUrlFromHash(hash)}/${path}`
    );
    return heroFetched.data;
}

export async function fetchCards(
    nft: NFT,
    tokens: Array<BigNumberish>,
    chainId: number
): Promise<Array<Hero>> {
    const heroes = await Promise.all(
        tokens.map(async (i) => {
            const uri = await nft.tokenURI(i);
            const uriExtracted = constructCdnUrl(uri, chainId);
            const hero = await axios.get<Hero>(uriExtracted.cdn);
            hero.data.tokenId = Number(i.toString());
            return hero.data;
        })
    );
    return heroes;
}

export enum StaticTraits {
    HeroType = "Hero Type",
    Location = "Location",
    Level = "Hero Level",
    Shard = "Clue Shard",
    Eyes = "Eyes",
    Mouth = "Mouth",
    Clothes = "Clothes",
}
